import { create } from 'zustand'
import { DogDetail, getDogById } from '../../services/edx-api.ts'
import { DiagnosticsDetail, getDemoDataDiagnosticsDetail } from './state-diagnostics.ts'
import { getMazikeenDiagnostics } from './fixture-data-demo.ts'

export enum DogProfileTabs {
  Profile = 'profile',
  Behavior = 'behavior',
  Coat = 'coat',
  Physical = 'physical',
  Health = 'health',
  DogsLikeMe = 'dogs-like-me',
  FindMyPack = 'find-my-pack',
  Resources = 'resources',
}

export enum DogResourcesTabs {
  Products = 'products',
  Services = 'services',
}

export type Review = {
  body: string
  rating: number
  username: string
}

export type RecommendedProductCategory = {
  description: string //Opal likely barks persistently when alarmed or excited. Here are a few recommended products that might help you and Opal in the future.
  title: string //barking
  recommendation: {
    value: number // 1-5
    title: string //Barking
    subTitle: string //Likely increased barking
  }
  productList: {
    description: string
    features: string[]
    productId: string
    imageUrl: string
    price: string
    title: string
    subtitle: string
    rating: number
    reviews: Review[]
  }[]
}

export type RecommendedServiceCategory = {
  description: string
  title: string
  recommendation: {
    value: number // 1-5
    title: string
    subTitle: string
  }
  serviceList: {
    description: string
    features: string[]
    serviceId: string
    imageUrl: string
    title: string
    subtitle: string
    rating: number
    reviews: Review[]
  }[]
}

export type DogResources = {
  actions: {
    setActiveTab: (tab: DogResourcesTabs) => void
    setViewProductId: (productId: string) => void
    setViewServiceId: (serviceId: string) => void
  }
  activeTab: DogResourcesTabs

  products: RecommendedProductCategory[]
  services: RecommendedServiceCategory[]
  // Show modal with product details
  viewProductId: string
  // Show modal with service details
  viewServiceId: string
}

export type DogProfileState = {
  actions: {
    loadDog: (dogId: number) => void
    setActiveTab: (tab: DogProfileTabs) => void
  }
  activeTab: DogProfileTabs
  dog: DogDetail
  diagnosticsDetail: DiagnosticsDetail
  isLoading: boolean
  resources: DogResources
}

export const useDogProfileState = create<DogProfileState>((set, get) => ({
  actions: {
    loadDog: async (dogId) => {
      set({ isLoading: true })
      const dog = await getDogById(dogId)
      set({ dog, isLoading: false })
    },
    setActiveTab: (tab) => set({ activeTab: tab }),
  },
  activeTab: DogProfileTabs.Profile,
  dog: {},
  diagnosticsDetail: getMazikeenDiagnostics(),
  isLoading: false,
  resources: {
    actions: {
      setActiveTab: (tab) =>
        set({
          resources: {
            ...get().resources,
            activeTab: tab,
          },
        }),
      setViewProductId: (productId) =>
        set({
          resources: {
            ...get().resources,
            viewProductId: productId,
          },
        }),
      setViewServiceId: (serviceId) =>
        set({
          resources: {
            ...get().resources,
            viewServiceId: serviceId,
          },
        }),
    },
    activeTab: DogResourcesTabs.Products,
    products: [
      {
        description: 'Mazikeen might need some help with training.',
        title: 'Training',
        recommendation: {
          value: 4,
          title: 'Trainability',
          subTitle: 'Likely difficult training',
        },
        productList: [
          {
            title: 'Waterproof Dog Training Collar',
            description:
              'A paw-fect housetraining aid for dogs of all ages, teach your pup to ring the bell to signal when he needs to potty outside.',
            features: [],
            productId: '2',
            price: '$59.99',
            imageUrl: '/demo/training-collar.png',
            subtitle: '2000ft Range, Rechargeable, Waterproof',
            rating: 5,
            reviews: [
              { username: 'Cory', rating: 5, body: 'I love this product!' },
              { username: 'Jesse', rating: 3, body: 'Works well' },
              { username: 'Christian M.', rating: 4, body: 'Great product, will buy again' },
            ],
          },
          {
            title: 'Training Treats',
            description:
              'A paw-fect training aid for dogs of all ages, these treats are perfect for rewarding your pup for good behavior.',
            features: [],
            productId: '3',
            price: '$14.99',
            imageUrl: '/demo/training-treats.png',
            subtitle: 'Chicken Recipe, 16 oz',
            rating: 4,
            reviews: [
              { username: 'Cory', rating: 4, body: 'My dog loves these treats!' },
              {
                username: 'George Albiem',
                rating: 5,
                body: 'My dog was sensitive to other treats but these are perfect!',
              },
            ],
          },
          {
            title: "Caldwell's Potty Bells",
            description:
              'A paw-fect housetraining aid for dogs of all ages, teach your pup to ring the bell to signal when he needs to potty outside.',
            features: [],
            productId: '1',
            price: '$9.99',
            imageUrl: '/demo/potty-bells.png',
            subtitle: 'Original Dog Doorbell, Black, 1 count',
            rating: 3,
            reviews: [
              { username: 'Joe Joe', rating: 3, body: 'These worked after a few weeks, took awhile to train my dog.' },
              { username: 'Kylie Ann', rating: 4, body: 'A great product, my dog learned quickly.' },
              { username: 'Nichole', rating: 3, body: 'This product is okay, my dog still has accidents.' },
            ],
          },
        ],
      },
      {
        title: 'Escape Artist',
        description:
          'Mazikeen likely has a high prey drive and may try to escape. Here are a few recommended products that might help you and Mazikeen in the future.',
        recommendation: {
          value: 2,
          title: 'Escaping',
          subTitle: 'Likely increased escaping',
        },
        productList: [
          {
            description:
              'This sturdy dog crate has a large single door that is easy to open, close and securely lock, with dual latches on larger models to create a safe space for your pup.',
            features: [],
            productId: '4',
            imageUrl: '/demo/dog-crate.png',
            price: '$99.99',
            title: 'Dog Crate',
            subtitle: 'Double Door Folding Metal Dog Crate',
            rating: 5,
            reviews: [
              { username: 'Cory', rating: 5, body: 'Very sturdy and easy to use.' },
              { username: 'Cory', rating: 3, body: 'Great product!' },
            ],
          },

          {
            title: 'Tieout Trolley Rope',
            description: 'Trolley has 4200 pounds break strength so not even the toughest dogs can break free.',
            features: [],
            productId: '4',
            imageUrl: '/demo/yard-rope.png',
            price: '$39.99',
            subtitle: 'Dog Tie Out Trolley System',
            rating: 3,
            reviews: [
              {
                username: 'Cory',
                rating: 3,
                body: 'Great product, but my dog still managed to escape.',
              },
              { username: 'Cory', rating: 3, body: 'Great product!' },
              {
                username: 'Annie',
                rating: 5,
                body: 'This is excellent in the summer months when we are outside a lot.',
              },
            ],
          },
        ],
      },
    ],
    services: [
      {
        description:
          'Mazikeen might need some help with training. Here are a few recommended services that might help you and Mazikeen in the future.',
        title: 'Training',
        recommendation: {
          value: 3,
          title: 'Training',
          subTitle: 'Likely difficult training',
        },
        serviceList: [
          {
            title: 'K9 Pro Training',
            description: 'K9 Pro Training offers a variety of training services for dogs of all ages and breeds.',
            features: [],
            serviceId: '1',
            imageUrl: '/demo/dog-training.png',
            subtitle: 'Basic Obedience Training',
            rating: 5,
            reviews: [
              {
                username: 'Chris L.',
                rating: 5,
                body: 'Very professional and great with my dog!',
              },
            ],
          },
        ],
      },
      {
        description:
          'Mazikeen likely energy and could benefit from walking services. Here are a few recommended services that might help you and Mazikeen in the future.',
        title: 'High Energy',
        recommendation: {
          value: 4.6,
          title: 'High Energy',
          subTitle: 'Likely high energy',
        },
        serviceList: [
          {
            title: 'Pet Friends Walking',
            description: 'Pet Friends provides dog walking services for dogs of all ages and breeds.',
            features: [],
            serviceId: '2',
            imageUrl: '/demo/dog-walking.png',
            subtitle: 'Dog Walking',
            rating: 5,
            reviews: [
              { username: 'Nick', rating: 5, body: 'Friendly staff and great with my dog!' },
              {
                username: 'Katie',
                rating: 5,
                body: 'My dog loves his walks with Pet Friends!',
              },
              {
                username: 'Jenny',
                rating: 5,
                body: 'Pet Friends has been a lifesaver for me and my dog!',
              },
            ],
          },
        ],
      },
    ],
    viewProductId: '',
    viewServiceId: '',
  },
}))
