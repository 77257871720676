import { Navigate, Route, Routes } from 'react-router-dom'
import { DashboardScreen } from './screens/DashboardScreen'
import { AppWrapper } from './components/AppWrapper.tsx'
import { TestProductScreen } from './screens/TestProductScreen.tsx'
import { ActivationScreen } from './screens/ActivationScreen'
import { useAuthWrapperContext } from '@edx/web-auth'
import { useEffect } from 'react'
import { useAppState } from './state/app.ts'
import { DogProfileScreen } from './screens/DogProfileScreen'

export function AppRoutes() {
  const appState = useAppState()
  const authState = useAuthWrapperContext().authState

  useEffect(() => {
    if (authState.email.includes('+demo@etalondx.com') || authState.email.includes('etalonautomation@etalondx.com')) {
      appState.setIsDemo(true)
    } else {
      appState.setIsDemo(false)
    }
  }, [authState])

  return (
    <AppWrapper>
      <Routes>
        <Route element={<ActivationScreen />} path="/activation" />
        <Route element={<TestProductScreen />} path="/products/test" />
        <Route element={<DogProfileScreen />} path="/dog-profile/:dogId" />
        <Route element={<DashboardScreen />} path="/" />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AppWrapper>
  )
}
