import React from 'react'
import { Button, Dialog, DialogContent, DialogHeader, DialogTitle } from '@edx/react-common'
import { FaCartPlus, FaPaw, FaUser } from 'react-icons/fa6'
import { PawRating } from '../../../../components/PawRating.tsx'
import { RecommendedServiceCategory } from '../../state.ts'

interface ServiceDetailDialogProps {
  onClose: () => void
  service: RecommendedServiceCategory['serviceList'][0]
}

export function ServiceDetailDialog({ onClose, service }: ServiceDetailDialogProps) {
  return (
    <Dialog open onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="bg-gradient-to-b from-doggradient-300 to-doggradient-100 shadow-md border-2 border-white backdrop-blur-[50px] rounded-3xl bg-[unset]">
        <div className="flex justify-center">
          <img
            className="border border-gray-300 shadow rounded-full w-[150px] h-[150px] object-cover"
            src={service.imageUrl}
            alt="service"
          />
        </div>

        <div className="font-semibold text-center">{service.title}</div>

        <div className="text-sm font-light text-center">{service.subtitle}</div>

        <PawRating value={service.rating} />

        {service.features.length > 0 && (
          <div className="flex justify-center">
            <ul className="flex gap-4 space-x-2 list-disc">
              {service.features.map((feature) => (
                <li key={feature}>{feature}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="bg-gray-100 p-2 text-sm rounded-2xl">{service.description}</div>

        <div className="flex gap-4 justify-center items-center">
          <Button className="text-white rounded-2xl w-full" variant="brand">
            Contact Provider
          </Button>
        </div>

        <div className="text-center">
          <FaPaw className="inline mr-1 opacity-65 text-edxred-500" /> {service.rating} Paw Rating{' '}
          <span className="text-edxred-500">{service.reviews.length} reviews</span>
        </div>

        <div className="space-y-3">
          {service.reviews.map((review) => (
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center">
                <span className="flex gap-3 items-center">
                  <FaUser className="bg-gray-300 rounded-full p-1" size={30} /> {review.username}
                </span>

                <PawRating value={review.rating} />
              </div>

              <div className="ml-10">{review.body}</div>
            </div>
          ))}

          <div className="text-center text-gray-400 text-sm underline cursor-pointer">See All Reviews</div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
