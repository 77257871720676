import React from 'react'
import { FaCircleInfo } from 'react-icons/fa6'
import { ServiceCard } from '../Services/ServiceCard.tsx'
import { Button } from '@edx/react-common'
import { RecommendedServiceCategory } from '../../state.ts'

interface RecommendedServicesCategoryHorizontalProps {
  onClickProduct: (productId: string) => void
  recommendedCategory: RecommendedServiceCategory
}

export function RecommendedServicesCategoryHorizontal({
  onClickProduct,
  recommendedCategory,
}: RecommendedServicesCategoryHorizontalProps) {
  return (
    <div className="flex gap-4 py-4">
      <div className="w-1/3">
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col gap-2">
            <div className="font-semibold">{recommendedCategory.title}</div>

            <div className="font-light text-sm">{recommendedCategory.description}</div>

            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <div className="font-semibold">{recommendedCategory.recommendation.title}</div>

                <FaCircleInfo />
              </div>

              <div className="w-full border border-edxred-500 relative rounded-lg">
                <div
                  className="min-w-0 bg-edxred-500 w-full h-2 m-1 rounded-lg"
                  style={{ width: `${recommendedCategory.recommendation.value * 20}%` }}
                ></div>
              </div>

              <div>{recommendedCategory.recommendation.subTitle}</div>
            </div>
          </div>

          <div className="w-full">
            <Button className="w-full" variant="outline" size="sm">
              Show All
            </Button>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-x-scroll">
        <div className="flex gap-2 w-full">
          {recommendedCategory.serviceList.map((service) => (
            <ServiceCard
              key={service.serviceId}
              onClickService={() => onClickProduct(service.serviceId)}
              service={service}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
