import React from 'react'
import { Badge, Card, CardContent, CardHeader, CardTitle } from '@edx/react-common'
import { useDiagnosticsState } from '../../../state-diagnostics.ts'
import { DogCard } from '../../../../../components/DogCard.tsx'
import { useDogProfileState } from '../../../state.ts'

interface DiagnosticsPhysicalCardProps {}

export function DiagnosticsPhysicalCard({}: DiagnosticsPhysicalCardProps) {
  const diagnostics = useDogProfileState((state) => state.diagnosticsDetail)
  const physicalTraits = []
  for (const key in diagnostics.physicalTraits) {
    physicalTraits.push(diagnostics.physicalTraits[key])
  }

  return (
    <DogCard>
      <CardHeader>
        <CardTitle className="text-edxred-500 font-montserrat text-lg uppercase">Physical Traits</CardTitle>
      </CardHeader>

      <div className="p-6 pt-0">
        <hr />
      </div>

      <CardContent>
        <div className="grid grid-cols-2 gap-x-8 gap-y-4">
          {physicalTraits.slice(0, 8).map((trait) => {
            return (
              <Card className="drop-shadow-sm">
                <CardHeader className="p-4">
                  <div>
                    <h4 className="font-bold text-lg">{trait.trait}</h4>
                  </div>
                </CardHeader>
                <CardContent className="p-4 pt-0">
                  <div className="flex justify-between">
                    {trait.notes} <Badge className="bg-dogred-400">{trait.genotype}</Badge>
                  </div>
                </CardContent>
              </Card>
            )
          })}
        </div>

        <div className="grid grid-cols-[42%,58%] mt-6 drop-shadow-sm">
          {physicalTraits.slice(4).map((trait) => {
            return (
              <>
                <div className="border border-t-0 border-r-0 p-4 first:border-t-[1px] first:rounded-tl-lg [&:nth-last-child(2)]:rounded-bl-lg">
                  <h4 className="font-bold">
                    {trait.trait}
                    <Badge className="bg-dogred-400 float-right">{trait.genotype}</Badge>
                  </h4>
                </div>
                <div className="border border-t-0 p-4 [&:nth-child(2)]:border-t-[1px] [&:nth-child(2)]:rounded-tr-lg last:rounded-br-lg">
                  {trait.notes}
                </div>
              </>
            )
          })}
        </div>
      </CardContent>
    </DogCard>
  )
}
