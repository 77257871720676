import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  cn,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@edx/react-common'
import { RecommendedProductsCategoryHorizontal } from './RecommendedProductsCategoryHorizontal.tsx'
import { DogResourcesTabs, useDogProfileState } from '../../state.ts'
import { RecommendedServicesCategoryHorizontal } from './RecommendedServicesCategoryHorizontal.tsx'
import { DogCard } from '../../../../components/DogCard.tsx'

interface ResourcesCardProps {}

export function ResourcesCard({}: ResourcesCardProps) {
  const state = useDogProfileState()

  return (
    <DogCard>
      <Tabs
        value={state.resources.activeTab}
        onValueChange={(tab) => state.resources.actions.setActiveTab(tab as DogResourcesTabs)}
      >
        <CardHeader className="gap-2">
          <CardTitle className="text-edxred-500 font-montserrat text-lg uppercase flex justify-between items-center">
            <span>Resources</span>

            <TabsList className="min-w-[250px]">
              <TabsTrigger className="w-full" value={DogResourcesTabs.Products}>
                <span
                  className={cn({
                    'font-semibold text-edxred-500': state.resources.activeTab === DogResourcesTabs.Products,
                  })}
                >
                  Products
                </span>
              </TabsTrigger>

              <TabsTrigger className="w-full" value={DogResourcesTabs.Services}>
                <span
                  className={cn({
                    'font-semibold text-edxred-500': state.resources.activeTab === DogResourcesTabs.Services,
                  })}
                >
                  Services
                </span>
              </TabsTrigger>
            </TabsList>
          </CardTitle>
          <hr />
        </CardHeader>

        <CardContent>
          <TabsContent value={DogResourcesTabs.Products}>
            {state.resources.products.map((productCategory) => (
              <RecommendedProductsCategoryHorizontal
                onClickProduct={(productId: string) => state.resources.actions.setViewProductId(productId)}
                recommendedCategory={productCategory}
              />
            ))}
          </TabsContent>

          <TabsContent value={DogResourcesTabs.Services}>
            {state.resources.services.map((serviceCategory) => (
              <RecommendedServicesCategoryHorizontal
                onClickProduct={(productId: string) => state.resources.actions.setViewServiceId(productId)}
                recommendedCategory={serviceCategory}
              />
            ))}
          </TabsContent>
        </CardContent>
      </Tabs>
    </DogCard>
  )
}
