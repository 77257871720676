import React from 'react'
import { Badge, Button, CardContent, CardHeader, CardTitle, LoadingSpinner } from '@edx/react-common'
import { MdEdit } from 'react-icons/md'
import { FaTimes } from 'react-icons/fa'
import { makeEdxImageUrl } from '../../../utils/make-edx-image-url.ts'
import { AnimalAge } from '../../../components/AnimalAge.tsx'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import { Environment } from '../../../../config/environment.ts'
import { DogButton } from '../../../components/DogButton.tsx'
import { FaFilePdf } from 'react-icons/fa6'
import { useDashboardScreenState } from '../state.ts'
import { useAppState } from '../../../state/app.ts'
import { CheckCircle, ChevronRight, Expand, Heart, Share } from 'lucide-react'
import dayjs from 'dayjs'

interface DogCardProps {}

export function DogCard({}: DogCardProps) {
  const { isDemo } = useAppState()
  const state = useDashboardScreenState()
  const navigate = useNavigate()

  if (!state.selectedDog) return null

  const dog = state.selectedDog.dog
  const hasDiagnosticsReport = !!dog?.diagnosticsReportUrl

  if (isDemo)
    return (
      <div className="bg-gradient-to-b from-doggradient-300/80 to-doggradient-100/80 shadow-md border-2 border-white backdrop-blur-[10px] rounded-3xl p-6 w-full max-w-[500px] flex flex-col relative">
        <div className="absolute top-5 right-5 cursor-pointer flex gap-4 text-gray-600 text-2xl">
          <div
            className="text-dogred-500"
            onClick={() => {
              state.actions.showDogForm(dog?.id)
            }}
          >
            <MdEdit />
          </div>

          <div
            className="text-dogred-500"
            onClick={() => {
              state.actions.selectDog(undefined)
            }}
          >
            <FaTimes />
          </div>
        </div>

        <CardHeader className="flex justify-between items-center flex-row">
          <CardTitle className="text-4xl font-bold text-red-800 min-w-0 text-ellipsis truncate">{dog?.name}</CardTitle>

          <img src="/us_flag.png" alt="US Flag" className="h-6" />
        </CardHeader>

        <CardContent className="space-y-4">
          <div className="flex justify-between items-center space-x-2">
            <div className="flex-1 text-lg truncate">
              {[dog?.dateOfBirth ? dayjs(dog?.dateOfBirth).format('YYYY') : '', dog?.gender, dog?.breed].map(
                (v, i, arr) =>
                  v ? (
                    <span className="min-w-0 text-ellipsis truncate">
                      {v}
                      {i < arr.length - 1 ? <span className="text-gray-400"> | </span> : ''}
                    </span>
                  ) : null,
              )}
            </div>

            {hasDiagnosticsReport && (
              <Badge className="bg-edxred-500">
                <div className="flex items-center gap-2">
                  <CheckCircle className="h-4 w-4" />
                  <span className="font-semibold">Tested & Verified</span>
                </div>
              </Badge>
            )}
          </div>

          {dog?.avatar?.url ? (
            <img
              className="relative aspect-video bg-gray-100 rounded-md"
              src={makeEdxImageUrl(dog?.avatar?.url) || 'https://placehold.co/400x225?text=No+Photo'}
              alt=""
            />
          ) : (
            <div className="relative aspect-video bg-gray-100 rounded-md" />
          )}

          {hasDiagnosticsReport ? (
            <div className="flex flex-col space-y-2 py-6">
              <Link
                to={generatePath(`/dog-profile/:dogId`, {
                  dogId: dog.id!.toString(),
                })}
              >
                <DogButton classNames="flex justify-between">
                  View Diagnostic Report
                  <ChevronRight className="h-5 w-5" />
                </DogButton>
              </Link>
            </div>
          ) : (
            <div className="space-y-2">
              <h2 className="text-lg italic text-gray-500">This canine does not have any DNA tests</h2>

              <DogButton
                onClickFn={() =>
                  (window.location.href = 'https://www.alphadogdna.com/products/the-essential-dog-panel')
                }
                classNames="text-white"
                variant="brand"
              >
                Puchase Test
              </DogButton>
              <div className="text-center text-gray-500 text-sm font-semibold">OR</div>
              <DogButton onClickFn={() => navigate('/activation')} variant="ghost">
                Activate Test
              </DogButton>
            </div>
          )}
        </CardContent>

        {state.dogsLoading && (
          <div className="absolute bg-white/50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            <LoadingSpinner size={52} />
          </div>
        )}
      </div>
    )

  return (
    <div className="bg-gradient-to-b from-doggradient-300/80 to-doggradient-100/80 shadow-md border-2 border-white backdrop-blur-[10px] rounded-3xl p-8 w-full max-w-[500px] flex flex-col space-y-6 relative">
      {state.selectedDog.loading && (
        <div className="absolute bg-white/50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
          <LoadingSpinner size={52} />
        </div>
      )}

      <div className="absolute top-5 right-5 cursor-pointer flex gap-4 text-gray-600 text-2xl">
        <div
          className="text-dogred-500"
          onClick={() => {
            state.actions.showDogForm(dog?.id)
          }}
        >
          <MdEdit />
        </div>

        <div
          className="text-dogred-500"
          onClick={() => {
            state.actions.selectDog(undefined)
          }}
        >
          <FaTimes />
        </div>
      </div>

      <div className="flex justify-center">
        <div
          className="bg-gray-300 rounded w-48 h-32"
          style={{
            backgroundImage: `url(${makeEdxImageUrl(dog?.avatar?.url) || 'https://placehold.co/216x144?text=No+Photo'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
      </div>

      <div className="font-semibold text-2xl text-dogred-500 text-center">{state.selectedDog.dog?.name}</div>

      <div className="font-medium text-center text-dogred-500 text-sm mb-4">
        <div>
          <AnimalAge dob={state.selectedDog.dog?.dateOfBirth ?? ''} />
        </div>
        <div>{state.selectedDog.dog?.breed}</div>
        <div>{state.selectedDog.dog?.discipline}</div>
      </div>

      {state.selectedDog.dog?.diagnosticsReportUrl && (
        <Link
          className="font-montserrat text-edxred-500"
          to={`${Environment.RESULTS_PREFIX_URL}${state.selectedDog.dog?.diagnosticsReportUrl}`}
          target="_blank"
        >
          <DogButton>Diagnostic Report</DogButton>
        </Link>
      )}
      {!state.selectedDog.dog?.diagnosticsReportUrl && (
        <DogButton classNames="font-montserrat text-edxred-500" disabled={true}>
          Diagnostic Report
        </DogButton>
      )}

      <div className="flex justify-center">
        <FaFilePdf className="text-3xl" />
      </div>
    </div>
  )
}
