import React from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@edx/react-common'
import { Environment } from '../../config/environment.ts'

interface DiagnosticsReportDialogProps {
  diagnosticsReportUrl: string
  onClose: () => void
}

export function DiagnosticsReportDialog({ diagnosticsReportUrl, onClose }: DiagnosticsReportDialogProps) {
  return (
    <Dialog open onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="min-w-[90vw] bg-gradient-to-b from-doggradient-300 to-doggradient-100 shadow-md border-2 border-white backdrop-blur-[50px] rounded-3xl bg-[unset]">
        <DialogHeader>
          <DialogTitle className="text-2xl">Diagnotics Report</DialogTitle>
        </DialogHeader>

        <iframe
          src={`${Environment.RESULTS_PREFIX_URL}${diagnosticsReportUrl}`}
          className="w-full h-[85vh] border-none rounded"
        />
      </DialogContent>
    </Dialog>
  )
}
