import { DiagnosticsDetail } from './state-diagnostics.ts'

const mazikeen: DiagnosticsDetail = {
  behaviorTraits: {
    Attachment: {
      trait: 'Attachment',
      result: 'unlikely',
    },
    Barking: {
      trait: 'Barking',
      result: 'unlikely',
    },
    Chasing: {
      trait: 'Chasing',
      result: 'unlikely',
    },
    'Dog Aggression': {
      trait: 'Dog Aggression',
      result: 'unlikely',
    },
    'Dog Fear': {
      trait: 'Dog Fear',
      result: 'unlikely',
    },
    Energy: {
      trait: 'Energy',
      result: 'unlikely',
    },
    Escaping: {
      trait: 'Escaping',
      result: 'likely',
    },
    Excitability: {
      trait: 'Excitability',
      result: 'possible',
    },
    'Familiar Dog Aggression': {
      trait: 'Familiar Dog Aggression',
      result: 'unlikely',
    },
    'Non Social Fear': {
      trait: 'Non Social Fear',
      result: 'unlikely',
    },
    'Owner Aggression': {
      trait: 'Owner Aggression',
      result: 'possible',
    },
    'Rivalry Aggression': {
      trait: 'Rivalry Aggression',
      result: 'unlikely',
    },
    'Separation Anxiety': {
      trait: 'Separation Anxiety',
      result: 'unlikely',
    },
    'Separation Urination': {
      trait: 'Separation Urination',
      result: 'unlikely',
    },
    'Stranger Aggression': {
      trait: 'Stranger Aggression',
      result: 'unlikely',
    },
    'Stranger Fear': {
      trait: 'Stranger Fear',
      result: 'unlikely',
    },
    'Touch Sensitivity': {
      trait: 'Touch Sensitivity',
      result: 'possible',
    },
    Trainability: {
      trait: 'Trainability',
      result: 'unlikely',
    },
  },
  coatTraits: {
    'A Locus (Agouti)': {
      trait: 'A Locus (Agouti)',
      genotype: 'Unknown Combination',
    },
    'As Locus (Saddle Tan)': {
      trait: 'As Locus (Saddle Tan)',
      genotype: 'Saddle Tan',
      notes: 'non saddle Carrier',
    },
    'B Locus (Brown)': {
      trait: 'B Locus (Brown)',
      genotype: 'B/B',
      notes: 'Black coat, nose and pads',
    },
    'Co Locus (Cocoa)': {
      trait: 'Co Locus (Cocoa)',
      genotype: 'Cocoa',
      notes: 'Black Coat, Pads, Nose',
    },
    'Cu Locus (Curly Hair)': {
      trait: 'Cu Locus (Curly Hair)',
      genotype: 'Curly/Wavy',
      notes: 'Carrier',
    },
    'D Locus (Dilute)': {
      trait: 'D Locus (Dilute)',
      genotype: 'Non-dilute',
    },
    'E Locus': {
      trait: 'E Locus',
      genotype: 'Melanistic Mask',
    },
    'H Locus (Harlequin)': {
      trait: 'H Locus (Harlequin)',
      genotype: 'No Harlequin',
    },
    'I Locus (Intensity)': {
      trait: 'I Locus (Intensity)',
      genotype: 'Normal Intensity',
    },
    'IC Locus (Furnishings/IC)': {
      trait: 'IC Locus (Furnishings/IC)',
      genotype: 'Improper Coat',
    },
    'K Locus (Dominant Black)': {
      trait: 'K Locus (Dominant Black)',
      genotype: 'No Agouti Expression',
      notes: 'Carrier',
    },
    'L Locus (Long Hair/Fluffy)': {
      trait: 'L Locus (Long Hair/Fluffy)',
      genotype: 'Longhaired',
    },
    'M Locus (Merle)': {
      trait: 'M Locus (Merle)',
      genotype: 'Non-Merle',
    },
    'R Locus (Roan/Ticked)': {
      trait: 'R Locus (Roan/Ticked)',
      genotype: 'Roan',
      notes: 'carries ticking',
    },
    'S Locus (White Spotting)': {
      trait: 'S Locus (White Spotting)',
      genotype: 'No White Spotting',
    },
    'SD Locus (Shedding)': {
      trait: 'SD Locus (Shedding)',
      genotype: 'High Shedding',
    },
    'Predicted Coat Color': {
      trait: 'Predicted Coat Color',
      genotype: 'Black',
    },
    'Predicted Coat Type': {
      trait: 'Predicted Coat Type',
      genotype: 'Curly',
    },
  },
  healthTraits: {
    'Copper Toxicosis (Labrador Retriever Type)': {
      trait: 'Copper Toxicosis (Labrador Retriever Type)',
      result: 'affected',
      notes: 'One Variant Detected: Animal Possibly/Likely Affected',
    },
    'Cardiomyopathy and Juvenile Mortality': {
      trait: 'Cardiomyopathy and Juvenile Mortality',
      result: 'carrier',
      notes: 'One Variant Detected: Animal Unlikely Affected',
    },
    'Copper Toxicosis Protective Mutation (Labrador Retriever Type)': {
      trait: 'Copper Toxicosis Protective Mutation (Labrador Retriever Type)',
      result: 'carrier',
      notes: 'One Variant Detected: Animal Unlikely Affected',
    },
    '2,8-dihydroxyadenine (DHA) Urolithiasis': {
      trait: '2,8-dihydroxyadenine (DHA) Urolithiasis',
      result: 'clear',
    },
    'Acral Mutilation Syndrome (AMS)': {
      trait: 'Acral Mutilation Syndrome (AMS)',
      result: 'clear',
    },
    'Acute Respiratory Distress Syndrome (Discovered in the Dalmatian; ARDS)': {
      trait: 'Acute Respiratory Distress Syndrome (Discovered in the Dalmatian; ARDS)',
      result: 'clear',
    },
    'Afibrinogenemia (Dachshund Type)': {
      trait: 'Afibrinogenemia (Dachshund Type)',
      result: 'clear',
    },
    'Alaskan Husky Encephalopathy (AHE), Subacute Necrotising Encephalopathy': {
      trait: 'Alaskan Husky Encephalopathy (AHE), Subacute Necrotising Encephalopathy',
      result: 'clear',
    },
    'Alexander Disease (Discovered in the Labrador Retriever)': {
      trait: 'Alexander Disease (Discovered in the Labrador Retriever)',
      result: 'clear',
    },
    'Amelogenesis Imperfecta (Discovered in the Italian Greyhound; AI)': {
      trait: 'Amelogenesis Imperfecta (Discovered in the Italian Greyhound; AI)',
      result: 'clear',
    },
    'Amelogenesis Imperfecta (Discovered in the Parson Russell Terrier; AI)': {
      trait: 'Amelogenesis Imperfecta (Discovered in the Parson Russell Terrier; AI)',
      result: 'clear',
    },
    'Amelogenesis Imperfecta (Samoyed Type)': {
      trait: 'Amelogenesis Imperfecta (Samoyed Type)',
      result: 'clear',
    },
    'Autosomal Recessive Neuronal Degeneration': {
      trait: 'Autosomal Recessive Neuronal Degeneration',
      result: 'clear',
    },
    'Autosomal Recessive Severe Combined Immunodeficiency (ARSCID)': {
      trait: 'Autosomal Recessive Severe Combined Immunodeficiency (ARSCID)',
      result: 'clear',
    },
    'Bald Thigh Syndrome (Discovered in Sighthounds)': {
      trait: 'Bald Thigh Syndrome (Discovered in Sighthounds)',
      result: 'clear',
    },
    "Bandera's Neonatal Ataxia (BNAt)": {
      trait: "Bandera's Neonatal Ataxia (BNAt)",
      result: 'clear',
    },
    'Bardet-Biedl syndrome 2 or Progressive Retinal Atrophy (BBS2-PRA)': {
      trait: 'Bardet-Biedl syndrome 2 or Progressive Retinal Atrophy (BBS2-PRA)',
      result: 'clear',
    },
    'Benign Familial Juvenile Epilepsy (Discovered in the Lagotto Romagnolo; BFJE)': {
      trait: 'Benign Familial Juvenile Epilepsy (Discovered in the Lagotto Romagnolo; BFJE)',
      result: 'clear',
    },
    'Bernard-Soulier Syndrome (Discovered in the Cocker Spaniel)': {
      trait: 'Bernard-Soulier Syndrome (Discovered in the Cocker Spaniel)',
      result: 'clear',
    },
    'Bleeding disorder (Discovered in the Greater Swiss Mountain Dog; P2RY12-related)': {
      trait: 'Bleeding disorder (Discovered in the Greater Swiss Mountain Dog; P2RY12-related)',
      result: 'clear',
    },
    'CNS atrophy and Cerebellar Ataxia (SELENOP)': {
      trait: 'CNS atrophy and Cerebellar Ataxia (SELENOP)',
      result: 'clear',
    },
    'Canine Leukocyte Adhesion Deficiency (CLAD), Type III': {
      trait: 'Canine Leukocyte Adhesion Deficiency (CLAD), Type III',
      result: 'clear',
    },
    'Canine Multifocal Retinopathy 1 (Discovered in Mastiff-related breeds; CMR1)': {
      trait: 'Canine Multifocal Retinopathy 1 (Discovered in Mastiff-related breeds; CMR1)',
      result: 'clear',
    },
    'Canine Multifocal Retinopathy 2 (Discovered in the Coton de Tulear; CMR2)': {
      trait: 'Canine Multifocal Retinopathy 2 (Discovered in the Coton de Tulear; CMR2)',
      result: 'clear',
    },
    'Canine Multifocal Retinopathy 3 (Discovered in the Lapponian Herder; CMR3)': {
      trait: 'Canine Multifocal Retinopathy 3 (Discovered in the Lapponian Herder; CMR3)',
      result: 'clear',
    },
    'Canine Multiple System Degeneration (Kerry Blue Terrier Type)': {
      trait: 'Canine Multiple System Degeneration (Kerry Blue Terrier Type)',
      result: 'clear',
    },
    'Canine Multiple Systems Degeneration (Discovered in the Chinese Crested Dog; CMSD)': {
      trait: 'Canine Multiple Systems Degeneration (Discovered in the Chinese Crested Dog; CMSD)',
      result: 'clear',
    },
    'Canine Scott Syndrome (CSS)': {
      trait: 'Canine Scott Syndrome (CSS)',
      result: 'clear',
    },
    'Centronuclear Myopathy (Discovered in the Great Dane; BIN1-related; CNM)': {
      trait: 'Centronuclear Myopathy (Discovered in the Great Dane; BIN1-related; CNM)',
      result: 'clear',
    },
    'Centronuclear myopathy in Labrador Retriever': {
      trait: 'Centronuclear myopathy in Labrador Retriever',
      result: 'clear',
    },
    'Cerebellar Ataxia (Discovered in the Old English Sheepdog and the Gordon Setter)': {
      trait: 'Cerebellar Ataxia (Discovered in the Old English Sheepdog and the Gordon Setter)',
      result: 'clear',
    },
    'Cerebellar Ataxia (RALGAPA1)': {
      trait: 'Cerebellar Ataxia (RALGAPA1)',
      result: 'clear',
    },

    'Cerebellar Ataxia (SELENOP)': {
      trait: 'Cerebellar Ataxia (SELENOP)',
      result: 'clear',
    },
    'Cerebellar Ataxia (Spinone Italiano Type)': {
      trait: 'Cerebellar Ataxia (Spinone Italiano Type)',
      result: 'clear',
    },
    'Cerebellar Ataxia 2 (Belgian Shepherd Type)': {
      trait: 'Cerebellar Ataxia 2 (Belgian Shepherd Type)',
      result: 'clear',
    },
    'Cerebellar Cortical Degeneration (Discovered in the Vizsla; CCD)': {
      trait: 'Cerebellar Cortical Degeneration (Discovered in the Vizsla; CCD)',
      result: 'clear',
    },
    'Cerebellar Degeneration-Myositis Complex': {
      trait: 'Cerebellar Degeneration-Myositis Complex',
      result: 'clear',
    },
    'Cerebral Dysfunction (Discovered in the Friesian Stabyhoun)': {
      trait: 'Cerebral Dysfunction (Discovered in the Friesian Stabyhoun)',
      result: 'clear',
    },
    'Charcot-Marie-Tooth Disease': {
      trait: 'Charcot-Marie-Tooth Disease',
      result: 'clear',
    },
    'Chondrodysplasia, Disproportionate Short-limbed (ITGA10-related)': {
      trait: 'Chondrodysplasia, Disproportionate Short-limbed (ITGA10-related)',
      result: 'clear',
    },
    'Chondrodystrophy (CDDY and IVDD Risk) with or without Chondrodysplasia (CDPA)': {
      trait: 'Chondrodystrophy (CDDY and IVDD Risk) with or without Chondrodysplasia (CDPA)',
      result: 'clear',
    },
    'Chondrodystrophy and Intervertebral Disc Disease Risk (CDDY)': {
      trait: 'Chondrodystrophy and Intervertebral Disc Disease Risk (CDDY)',
      result: 'clear',
    },
    'Cleft Lip with/without Palate and Syndactyly (ADAMTS20-related)': {
      trait: 'Cleft Lip with/without Palate and Syndactyly (ADAMTS20-related)',
      result: 'clear',
    },
    'Cleft Palate (DLX6-related)': {
      trait: 'Cleft Palate (DLX6-related)',
      result: 'clear',
    },
    'Collie Eye Anomaly (CEA)': {
      trait: 'Collie Eye Anomaly (CEA)',
      result: 'clear',
    },
    'Complement 3 (C3) Deficiency': {
      trait: 'Complement 3 (C3) Deficiency',
      result: 'clear',
    },
    'Cone Degeneration (Discovered in the Alaskan Malamute)': {
      trait: 'Cone Degeneration (Discovered in the Alaskan Malamute)',
      result: 'clear',
    },
    'Cone Degeneration (Discovered in the German Shepherd Dog; CNGA3-related)': {
      trait: 'Cone Degeneration (Discovered in the German Shepherd Dog; CNGA3-related)',
      result: 'clear',
    },
    'Cone Degeneration (Discovered in the German Shorthaired Pointer; CNGB3-related)': {
      trait: 'Cone Degeneration (Discovered in the German Shorthaired Pointer; CNGB3-related)',
      result: 'clear',
    },
    'Cone Degeneration (Labrador Retriever Type)': {
      trait: 'Cone Degeneration (Labrador Retriever Type)',
      result: 'clear',
    },
    'Cone-Rod Dystrophy (Discovered in the Standard Wirehaired Dachshund; crd SWD)': {
      trait: 'Cone-Rod Dystrophy (Discovered in the Standard Wirehaired Dachshund; crd SWD)',
      result: 'clear',
    },
    'Cone-Rod Dystrophy (cord1-PRA/crd4)': {
      trait: 'Cone-Rod Dystrophy (cord1-PRA/crd4)',
      result: 'clear',
    },
    'Cone-Rod Dystrophy 1 (Discovered in the American Staffordshire Terrier; crd1)': {
      trait: 'Cone-Rod Dystrophy 1 (Discovered in the American Staffordshire Terrier; crd1)',
      result: 'clear',
    },
    'Cone-Rod Dystrophy 2 (Discovered in the Pit Bull Terrier; crd2)': {
      trait: 'Cone-Rod Dystrophy 2 (Discovered in the Pit Bull Terrier; crd2)',
      result: 'clear',
    },
    'Congenital Cornification Disorder (Discovered in the Chihuahua)': {
      trait: 'Congenital Cornification Disorder (Discovered in the Chihuahua)',
      result: 'clear',
    },
    'Congenital Dyshormonogenic Hypothyroidism with Goiter (Discovered in the Shih Tzu)': {
      trait: 'Congenital Dyshormonogenic Hypothyroidism with Goiter (Discovered in the Shih Tzu)',
      result: 'clear',
    },
    'Congenital Eye Malformation (Discovered in the Golden Retriever)': {
      trait: 'Congenital Eye Malformation (Discovered in the Golden Retriever)',
      result: 'clear',
    },
    'Congenital Hypothyroidism (Discovered in the Tenterfield Terrier)': {
      trait: 'Congenital Hypothyroidism (Discovered in the Tenterfield Terrier)',
      result: 'clear',
    },
    'Congenital Hypothyroidism (Discovered in the Toy Fox and Rat Terrier)': {
      trait: 'Congenital Hypothyroidism (Discovered in the Toy Fox and Rat Terrier)',
      result: 'clear',
    },
    'Congenital Hypothyroidism with Goiter (Spanish Water Dog Type)': {
      trait: 'Congenital Hypothyroidism with Goiter (Spanish Water Dog Type)',
      result: 'clear',
    },
    'Congenital Hypothyroidism with Goiter (Terrier Type)': {
      trait: 'Congenital Hypothyroidism with Goiter (Terrier Type)',
      result: 'clear',
    },
    'Congenital Idiopathic Megaesophagus Risk Factor (German Shepherd Type)': {
      trait: 'Congenital Idiopathic Megaesophagus Risk Factor (German Shepherd Type)',
      result: 'clear',
    },
    'Congenital Methemoglobinemia': {
      trait: 'Congenital Methemoglobinemia',
      result: 'clear',
    },
    'Congenital Myasthenic Syndrome (Discovered in the Golden Retriever; COLQ-related)': {
      trait: 'Congenital Myasthenic Syndrome (Discovered in the Golden Retriever; COLQ-related)',
      result: 'clear',
    },

    'Congenital Myasthenic Syndrome (Discovered in the Heideterrier; CHRNE-related; CMS)': {
      trait: 'Congenital Myasthenic Syndrome (Discovered in the Heideterrier; CHRNE-related; CMS)',
      result: 'clear',
    },
    'Congenital Myasthenic Syndrome (Discovered in the Jack Russell; CHRNE-related; CMS)': {
      trait: 'Congenital Myasthenic Syndrome (Discovered in the Jack Russell; CHRNE-related; CMS)',
      result: 'clear',
    },
    'Congenital Myasthenic Syndrome (Discovered in the Old Danish Pointer; CMS)': {
      trait: 'Congenital Myasthenic Syndrome (Discovered in the Old Danish Pointer; CMS)',
      result: 'clear',
    },
    'Congenital Myasthenic Syndrome (Golden Retriever Type)': {
      trait: 'Congenital Myasthenic Syndrome (Golden Retriever Type)',
      result: 'clear',
    },
    'Congenital Myasthenic Syndrome (Labrador Retriever Type)': {
      trait: 'Congenital Myasthenic Syndrome (Labrador Retriever Type)',
      result: 'clear',
    },
    'Congenital Nephrotic Syndrome (NPHS1-Related)': {
      trait: 'Congenital Nephrotic Syndrome (NPHS1-Related)',
      result: 'clear',
    },
    'Congenital Stationary Night Blindness (Discovered in the Briard; CSNB)': {
      trait: 'Congenital Stationary Night Blindness (Discovered in the Briard; CSNB)',
      result: 'clear',
    },
    'Craniomandibular Osteopathy (Discovered in the Cairn, Scottish and W.H White Terrier)': {
      trait: 'Craniomandibular Osteopathy (Discovered in the Cairn, Scottish and W.H White Terrier)',
      result: 'clear',
    },
    'Craniomandibular Osteopathy (Discovered in the Weimaraner; CMO)': {
      trait: 'Craniomandibular Osteopathy (Discovered in the Weimaraner; CMO)',
      result: 'clear',
    },
    'Cyclic Neutropenia': {
      trait: 'Cyclic Neutropenia',
      result: 'clear',
    },
    'Cystic Renal Dysplasia and Hepatic Fibrosis (Discovered in the Norwich Terrier)': {
      trait: 'Cystic Renal Dysplasia and Hepatic Fibrosis (Discovered in the Norwich Terrier)',
      result: 'clear',
    },
    'Cystinuria (Labrador Retriever Type)': {
      trait: 'Cystinuria (Labrador Retriever Type)',
      result: 'clear',
    },
    'Cystinuria Type I-A (Discovered in the Newfoundland Dog)': {
      trait: 'Cystinuria Type I-A (Discovered in the Newfoundland Dog)',
      result: 'clear',
    },
    'Cystinuria Type I-A (SLC3A1 p.S698G)': {
      trait: 'Cystinuria Type I-A (SLC3A1 p.S698G)',
      result: 'clear',
    },
    'Cystinuria Type I-B (SLC7A9 p.A217T)': {
      trait: 'Cystinuria Type I-B (SLC7A9 p.A217T)',
      result: 'clear',
    },
    'Cystinuria Type II-A (Discovered in the Australian Cattle Dog)': {
      trait: 'Cystinuria Type II-A (Discovered in the Australian Cattle Dog)',
      result: 'clear',
    },
    'Cystinuria Type II-B (Discovered in the Miniature Pinscher)': {
      trait: 'Cystinuria Type II-B (Discovered in the Miniature Pinscher)',
      result: 'clear',
    },
    'Dandy-Walker-Like Malformation (Discovered in the Eurasier; DWLM)': {
      trait: 'Dandy-Walker-Like Malformation (Discovered in the Eurasier; DWLM)',
      result: 'clear',
    },
    'Darier Disease and Associated Infundibular Cyst Formation': {
      trait: 'Darier Disease and Associated Infundibular Cyst Formation',
      result: 'clear',
    },
    'Deafness and Vestibular Dysfunction (Doberman Pinscher; MYO7A-related)': {
      trait: 'Deafness and Vestibular Dysfunction (Doberman Pinscher; MYO7A-related)',
      result: 'clear',
    },
    'Deafness and Vestibular Dysfunction (Doberman; PTPRQ-related)': {
      trait: 'Deafness and Vestibular Dysfunction (Doberman; PTPRQ-related)',
      result: 'clear',
    },
    'Degenerative Myelopathy (DM)': {
      trait: 'Degenerative Myelopathy (DM)',
      result: 'clear',
    },
    'Demyelinating Polyneuropathy (Discovered in the Miniature Schnauzer; SBF2-related)': {
      trait: 'Demyelinating Polyneuropathy (Discovered in the Miniature Schnauzer; SBF2-related)',
      result: 'clear',
    },
    'Dental Hypomineralisation (Discovered in the Border Collie)': {
      trait: 'Dental Hypomineralisation (Discovered in the Border Collie)',
      result: 'clear',
    },
    'Dilated Cardiomyopathy (Discovered in the Schnauzer; DCM)': {
      trait: 'Dilated Cardiomyopathy (Discovered in the Schnauzer; DCM)',
      result: 'clear',
    },
    'Dilated Cardiomyopathy risk factor (Discovered in the Doberman; TTN-related)': {
      trait: 'Dilated Cardiomyopathy risk factor (Discovered in the Doberman; TTN-related)',
      result: 'clear',
    },
    'Dilated Cardiomyopathy risk factor (PDK4-related)': {
      trait: 'Dilated Cardiomyopathy risk factor (PDK4-related)',
      result: 'clear',
    },
    'Dominant Progressive Retinal Atrophy (DPRA)': {
      trait: 'Dominant Progressive Retinal Atrophy (DPRA)',
      result: 'clear',
    },
    'Dry Eye Curly Coat Syndrome': {
      trait: 'Dry Eye Curly Coat Syndrome',
      result: 'clear',
    },
    'Dystrophic Epidermolysis Bullosa (Discovered in the Central Asian Ovcharka)': {
      trait: 'Dystrophic Epidermolysis Bullosa (Discovered in the Central Asian Ovcharka)',
      result: 'clear',
    },
    'Dystrophic Epidermolysis Bullosa (Discovered in the Golden Retriever)': {
      trait: 'Dystrophic Epidermolysis Bullosa (Discovered in the Golden Retriever)',
      result: 'clear',
    },
    'Early Retinal Degeneration (Discovered in the Norwegian Elkhound; erd)': {
      trait: 'Early Retinal Degeneration (Discovered in the Norwegian Elkhound; erd)',
      result: 'clear',
    },
    'Early-Onset Progressive Polyneuropathy (Discovered in the Alaskan Greyhound)': {
      trait: 'Early-Onset Progressive Polyneuropathy (Discovered in the Alaskan Greyhound)',
      result: 'clear',
    },
    'Early-Onset Progressive Polyneuropathy (Discovered in the Alaskan Malamute)': {
      trait: 'Early-Onset Progressive Polyneuropathy (Discovered in the Alaskan Malamute)',
      result: 'clear',
    },
    'Early-onset PRA (Discovered in the Portuguese Water Dog; EOPRA)': {
      trait: 'Early-onset PRA (Discovered in the Portuguese Water Dog; EOPRA)',
      result: 'clear',
    },
    'Ectodermal Dysplasia (Chesapeake Bay Retriever Type)': {
      trait: 'Ectodermal Dysplasia (Chesapeake Bay Retriever Type)',
      result: 'clear',
    },
    'Ehlers-Danlos Syndrome (Discovered in the Chihuahua and Poodle)': {
      trait: 'Ehlers-Danlos Syndrome (Discovered in the Chihuahua and Poodle)',
      result: 'clear',
    },
    'Ehlers-Danlos Syndrome (Doberman Pinscher Type)': {
      trait: 'Ehlers-Danlos Syndrome (Doberman Pinscher Type)',
      result: 'clear',
    },
    'Ehlers-Danlos Syndrome (Labrador Retriever Type), Variant 1': {
      trait: 'Ehlers-Danlos Syndrome (Labrador Retriever Type), Variant 1',
      result: 'clear',
    },
    'Ehlers-Danlos Syndrome (Labrador Retriever Type), Variant 2': {
      trait: 'Ehlers-Danlos Syndrome (Labrador Retriever Type), Variant 2',
      result: 'clear',
    },
    'Epidermolytic Hyperkeratosis (Discovered in the Norfolk Terrier)': {
      trait: 'Epidermolytic Hyperkeratosis (Discovered in the Norfolk Terrier)',
      result: 'clear',
    },
    'Episodic Falling (EF)': {
      trait: 'Episodic Falling (EF)',
      result: 'clear',
    },
    'Exercise-Induced Collapse (EIC)': {
      trait: 'Exercise-Induced Collapse (EIC)',
      result: 'clear',
    },
    'Exfoliative Cutaneous Lupus Erythematosus (ECLE)': {
      trait: 'Exfoliative Cutaneous Lupus Erythematosus (ECLE)',
      result: 'clear',
    },
    'Factor VII Deficiency': {
      trait: 'Factor VII Deficiency',
      result: 'clear',
    },
    'Factor XI Deficiency': {
      trait: 'Factor XI Deficiency',
      result: 'clear',
    },
    'Familial Nephropathy (Cocker Spaniel Type)': {
      trait: 'Familial Nephropathy (Cocker Spaniel Type)',
      result: 'clear',
    },
    'Familial Nephropathy (Discovered in the English Springer Spaniel; FN)': {
      trait: 'Familial Nephropathy (Discovered in the English Springer Spaniel; FN)',
      result: 'clear',
    },
    'Fanconi Syndrome': {
      trait: 'Fanconi Syndrome',
      result: 'clear',
    },
    'Fetal Onset Neuroaxonal Dystrophy (FNAD)': {
      trait: 'Fetal Onset Neuroaxonal Dystrophy (FNAD)',
      result: 'clear',
    },
    'Focal Non-Epidermolytic Palmoplantar Keratoderma': {
      trait: 'Focal Non-Epidermolytic Palmoplantar Keratoderma',
      result: 'clear',
    },
    Fucosidosis: {
      trait: 'Fucosidosis',
      result: 'clear',
    },
    'GM1 Gangliosidosis (Discovered in the Alaskan Husky)': {
      trait: 'GM1 Gangliosidosis (Discovered in the Alaskan Husky)',
      result: 'clear',
    },
    'GM1 Gangliosidosis (Discovered in the Portuguese Water Dog)': {
      trait: 'GM1 Gangliosidosis (Discovered in the Portuguese Water Dog)',
      result: 'clear',
    },
    'GM1 Gangliosidosis (Discovered in the Shiba)': {
      trait: 'GM1 Gangliosidosis (Discovered in the Shiba)',
      result: 'clear',
    },
    'GM2 Gangliosidosis (Discovered in the Japanese Chin)': {
      trait: 'GM2 Gangliosidosis (Discovered in the Japanese Chin)',
      result: 'clear',
    },
    'GM2 Gangliosidosis (Discovered in the Toy Poodle)': {
      trait: 'GM2 Gangliosidosis (Discovered in the Toy Poodle)',
      result: 'clear',
    },
    'Gallbladder Mucoceles': {
      trait: 'Gallbladder Mucoceles',
      result: 'clear',
    },
    'Glanzmann Thrombasthenia, Type I (Discovered in mixed breed dogs)': {
      trait: 'Glanzmann Thrombasthenia, Type I (Discovered in mixed breed dogs)',
      result: 'clear',
    },
    'Glanzmann Thrombasthenia, Type I (Discovered in the Great Pyrenees)': {
      trait: 'Glanzmann Thrombasthenia, Type I (Discovered in the Great Pyrenees)',
      result: 'clear',
    },
    'Glaucoma (Border Collie Type)': {
      trait: 'Glaucoma (Border Collie Type)',
      result: 'clear',
    },
    'Globoid Cell Leukodystrophy (Discovered in Terriers)': {
      trait: 'Globoid Cell Leukodystrophy (Discovered in Terriers)',
      result: 'clear',
    },
    'Globoid Cell Leukodystrophy (Discovered in the Irish Setter)': {
      trait: 'Globoid Cell Leukodystrophy (Discovered in the Irish Setter)',
      result: 'clear',
    },
    'Glycogen Storage Disease VII (Wachtelhund Type)': {
      trait: 'Glycogen Storage Disease VII (Wachtelhund Type)',
      result: 'clear',
    },

    'Glycogen Storage Disease, Type IIIa (GSD IIIa)': {
      trait: 'Glycogen Storage Disease, Type IIIa (GSD IIIa)',
      result: 'clear',
    },
    'Glycogen Storage Disease, Type Ia (GSD Ia)': {
      trait: 'Glycogen Storage Disease, Type Ia (GSD Ia)',
      result: 'clear',
    },
    'Glycogen storage disease VII (GSD VII) or Phosphofructokinase (PFK) Deficiency': {
      trait: 'Glycogen storage disease VII (GSD VII) or Phosphofructokinase (PFK) Deficiency',
      result: 'clear',
    },
    'Hemophilia A (Discovered in the Boxer)': {
      trait: 'Hemophilia A (Discovered in the Boxer)',
      result: 'clear',
    },
    'Hemophilia A (Discovered in the German Shepherd Dog; F8 p.C548Y)': {
      trait: 'Hemophilia A (Discovered in the German Shepherd Dog; F8 p.C548Y)',
      result: 'clear',
    },
    'Hemophilia A (Discovered in the German Shepherd Dog; F8 p.W33*)': {
      trait: 'Hemophilia A (Discovered in the German Shepherd Dog; F8 p.W33*)',
      result: 'clear',
    },
    'Hemophilia A (Discovered in the Havanese)': {
      trait: 'Hemophilia A (Discovered in the Havanese)',
      result: 'clear',
    },
    'Hemophilia A (Discovered in the Old English Sheepdog)': {
      trait: 'Hemophilia A (Discovered in the Old English Sheepdog)',
      result: 'clear',
    },
    'Hemophilia B (Discovered in the Airedale Terrier)': {
      trait: 'Hemophilia B (Discovered in the Airedale Terrier)',
      result: 'clear',
    },
    'Hemophilia B (Discovered in the Cairn Terrier)': {
      trait: 'Hemophilia B (Discovered in the Cairn Terrier)',
      result: 'clear',
    },
    'Hemophilia B (Discovered in the Lhasa Apso)': {
      trait: 'Hemophilia B (Discovered in the Lhasa Apso)',
      result: 'clear',
    },
    'Hemophilia B (Discovered in the Rhodesian Ridgeback)': {
      trait: 'Hemophilia B (Discovered in the Rhodesian Ridgeback)',
      result: 'clear',
    },
    'Hereditary Ataxia (Australian Shepherd Type)': {
      trait: 'Hereditary Ataxia (Australian Shepherd Type)',
      result: 'clear',
    },
    'Hereditary Ataxia (Discovered in the Norwegian Buhund; KCNIP4-related)': {
      trait: 'Hereditary Ataxia (Discovered in the Norwegian Buhund; KCNIP4-related)',
      result: 'clear',
    },
    'Hereditary Ataxia (Discovered in the Belgian Malinois; SLC12A6-related)': {
      trait: 'Hereditary Ataxia (Discovered in the Belgian Malinois; SLC12A6-related)',
      result: 'clear',
    },
    'Hereditary Cataracts (Wirehaired Pointing Griffon Type)': {
      trait: 'Hereditary Cataracts (Wirehaired Pointing Griffon Type)',
      result: 'clear',
    },
    'Hereditary Cataracts HSF4-Related (Deletion Mutation)': {
      trait: 'Hereditary Cataracts HSF4-Related (Deletion Mutation)',
      result: 'clear',
    },
    'Hereditary Cataracts HSF4-Related (Insertion Mutation)': {
      trait: 'Hereditary Cataracts HSF4-Related (Insertion Mutation)',
      result: 'clear',
    },
    'Hereditary Elliptocytosis': {
      trait: 'Hereditary Elliptocytosis',
      result: 'clear',
    },
    'Hereditary Footpad Hyperkeratosis (Discovered in the Irish Terrier and Kromfohrländer)': {
      trait: 'Hereditary Footpad Hyperkeratosis (Discovered in the Irish Terrier and Kromfohrländer)',
      result: 'clear',
    },
    'Hereditary Footpad Hyperkeratosis (Rottweiler Type)': {
      trait: 'Hereditary Footpad Hyperkeratosis (Rottweiler Type)',
      result: 'clear',
    },
    'Hereditary Nasal Parakeratosis (Discovered in the Greyhound; HNPK)': {
      trait: 'Hereditary Nasal Parakeratosis (Discovered in the Greyhound; HNPK)',
      result: 'clear',
    },
    'Hereditary Nasal Parakeratosis (Discovered in the Labrador Retriever; HNPK)': {
      trait: 'Hereditary Nasal Parakeratosis (Discovered in the Labrador Retriever; HNPK)',
      result: 'clear',
    },
    'Hereditary Vitamin D-Resistant Rickets Type II': {
      trait: 'Hereditary Vitamin D-Resistant Rickets Type II',
      result: 'clear',
    },
    'Hyperuricosuria (HUU)': {
      trait: 'Hyperuricosuria (HUU)',
      result: 'clear',
    },
    Hypocatalasia: {
      trait: 'Hypocatalasia',
      result: 'clear',
    },
    'Hypomyelination (Discovered in the Weimaraner)': {
      trait: 'Hypomyelination (Discovered in the Weimaraner)',
      result: 'clear',
    },
    'Hypophosphatasia (Discovered in the Karelian Bear Dog; ALPL-related)': {
      trait: 'Hypophosphatasia (Discovered in the Karelian Bear Dog; ALPL-related)',
      result: 'clear',
    },
    'Ichthyosis (Discovered in the American Bulldog; NIPAL4-related)': {
      trait: 'Ichthyosis (Discovered in the American Bulldog; NIPAL4-related)',
      result: 'clear',
    },
    'Ichthyosis (Discovered in the Golden Retriever)': {
      trait: 'Ichthyosis (Discovered in the Golden Retriever)',
      result: 'clear',
    },
    'Ichthyosis (Discovered in the Great Dane; SLC27A4-related)': {
      trait: 'Ichthyosis (Discovered in the Great Dane; SLC27A4-related)',
      result: 'clear',
    },
    'Ichthyosis (Golden Retriever Type 2)': {
      trait: 'Ichthyosis (Golden Retriever Type 2)',
      result: 'clear',
    },

    'Ichthyosis in Pembroke Welsh Corgi': {
      trait: 'Ichthyosis in Pembroke Welsh Corgi',
      result: 'clear',
    },
    'Inflammatory Myopathy (Discovered in the Dutch Shepherd Dog; SLC25A12-related)': {
      trait: 'Inflammatory Myopathy (Discovered in the Dutch Shepherd Dog; SLC25A12-related)',
      result: 'clear',
    },
    'Inguinal Cryptorchidism': {
      trait: 'Inguinal Cryptorchidism',
      result: 'clear',
    },
    'Intestinal Cobalamin Malabsorption (Discovered in the Beagle; CUBN-related)': {
      trait: 'Intestinal Cobalamin Malabsorption (Discovered in the Beagle; CUBN-related)',
      result: 'clear',
    },
    'Intestinal Cobalamin Malabsorption (Discovered in the Border Collie; CUBN-related)': {
      trait: 'Intestinal Cobalamin Malabsorption (Discovered in the Border Collie; CUBN-related)',
      result: 'clear',
    },
    'Intestinal Cobalamin Malabsorption (Discovered in the Komondor; CUBN-related)': {
      trait: 'Intestinal Cobalamin Malabsorption (Discovered in the Komondor; CUBN-related)',
      result: 'clear',
    },
    'Intestinal Cobalamin Malabsorption (Giant Schnauzer Type)': {
      trait: 'Intestinal Cobalamin Malabsorption (Giant Schnauzer Type)',
      result: 'clear',
    },
    'Juvenile Dermatomyositis, DMS, JDM': {
      trait: 'Juvenile Dermatomyositis, DMS, JDM',
      result: 'clear',
    },
    'Juvenile Encephalopathy (Discovered in the Parson Russell Terrier)': {
      trait: 'Juvenile Encephalopathy (Discovered in the Parson Russell Terrier)',
      result: 'clear',
    },
    'Juvenile Myoclonic Epilepsy (Discovered in the Rhodesian Ridgeback; JME)': {
      trait: 'Juvenile Myoclonic Epilepsy (Discovered in the Rhodesian Ridgeback; JME)',
      result: 'clear',
    },
    'L-2-Hydroxyglutaric Aciduria (Discovered in the Staffordshire Bull Terrier)': {
      trait: 'L-2-Hydroxyglutaric Aciduria (Discovered in the Staffordshire Bull Terrier)',
      result: 'clear',
    },
    'L-2-Hydroxyglutaric Aciduria (Discovered in the West Highland White Terrier)': {
      trait: 'L-2-Hydroxyglutaric Aciduria (Discovered in the West Highland White Terrier)',
      result: 'clear',
    },
    'Lagotto Storage Disease (LSD)': {
      trait: 'Lagotto Storage Disease (LSD)',
      result: 'clear',
    },
    'Lamellar Ichthyosis (LI)': {
      trait: 'Lamellar Ichthyosis (LI)',
      result: 'clear',
    },
    'Laryngeal Paralysis and Polyneuropathy (Leonberger Type 3)': {
      trait: 'Laryngeal Paralysis and Polyneuropathy (Leonberger Type 3)',
      result: 'clear',
    },
    'Leigh syndrome': {
      trait: 'Leigh syndrome',
      result: 'clear',
    },
    'Leonberger Polyneuropathy, Type 2 (LPN2)': {
      trait: 'Leonberger Polyneuropathy, Type 2 (LPN2)',
      result: 'clear',
    },
    'Lethal Acrodermatitis (Discovered in the Bull Terrier; LAD)': {
      trait: 'Lethal Acrodermatitis (Discovered in the Bull Terrier; LAD)',
      result: 'clear',
    },
    'Leukocyte Adhesion Deficiency, Type I': {
      trait: 'Leukocyte Adhesion Deficiency, Type I',
      result: 'clear',
    },
    Leukoencephalomyelopathy: {
      trait: 'Leukoencephalomyelopathy',
      result: 'clear',
    },
    'Ligneous Membranitis (Discovered in the Scottish Terrier)': {
      trait: 'Ligneous Membranitis (Discovered in the Scottish Terrier)',
      result: 'clear',
    },
    'Limb-Girdle Muscular Dystrophy (Dachshund Type)': {
      trait: 'Limb-Girdle Muscular Dystrophy (Dachshund Type)',
      result: 'clear',
    },
    'Limb-girdle Muscular Dystrophy, Type 2F (Discovered in the Boston Terrier)': {
      trait: 'Limb-girdle Muscular Dystrophy, Type 2F (Discovered in the Boston Terrier)',
      result: 'clear',
    },
    'Long QT Syndrome': {
      trait: 'Long QT Syndrome',
      result: 'clear',
    },
    'Lundehund Syndrome': {
      trait: 'Lundehund Syndrome',
      result: 'clear',
    },
    'Lung Developmental Disease (Discovered in the Airedale Terrier)': {
      trait: 'Lung Developmental Disease (Discovered in the Airedale Terrier)',
      result: 'clear',
    },
    'MDR1 (Multidrug Resistance 1) Medication Sensitivity': {
      trait: 'MDR1 (Multidrug Resistance 1) Medication Sensitivity',
      result: 'clear',
    },
    'Macrothrombocytopenia (Discovered in the Norfolk and Cairn Terrier)': {
      trait: 'Macrothrombocytopenia (Discovered in the Norfolk and Cairn Terrier)',
      result: 'clear',
    },
    'Macular Corneal Dystrophy (Labrador Retriever Type)': {
      trait: 'Macular Corneal Dystrophy (Labrador Retriever Type)',
      result: 'clear',
    },
    'May-Hegglin Anomaly (MHA)': {
      trait: 'May-Hegglin Anomaly (MHA)',
      result: 'clear',
    },
    'Microphthalmia (Discovered in the Soft-Coated Wheaten Terrier)': {
      trait: 'Microphthalmia (Discovered in the Soft-Coated Wheaten Terrier)',
      result: 'clear',
    },
    'Mucopolysaccharidosis I (Plott Hound Type)': {
      trait: 'Mucopolysaccharidosis I (Plott Hound Type)',
      result: 'clear',
    },

    'Mucopolysaccharidosis IIIB (Schipperke Type)': {
      trait: 'Mucopolysaccharidosis IIIB (Schipperke Type)',
      result: 'clear',
    },
    'Mucopolysaccharidosis, Type IIIA (Discovered in the Dachshund; MPS IIIA)': {
      trait: 'Mucopolysaccharidosis, Type IIIA (Discovered in the Dachshund; MPS IIIA)',
      result: 'clear',
    },
    'Mucopolysaccharidosis, Type IIIA (Discovered in the New Zealand Huntaway; MPS IIIA)': {
      trait: 'Mucopolysaccharidosis, Type IIIA (Discovered in the New Zealand Huntaway; MPS IIIA)',
      result: 'clear',
    },
    'Mucopolysaccharidosis, Type VII (Discovered in the Brazilian Terrier; MPS VII)': {
      trait: 'Mucopolysaccharidosis, Type VII (Discovered in the Brazilian Terrier; MPS VII)',
      result: 'clear',
    },
    'Mucopolysaccharidosis, Type VII (Discovered in the German Shepherd Dog; MPS VII)': {
      trait: 'Mucopolysaccharidosis, Type VII (Discovered in the German Shepherd Dog; MPS VII)',
      result: 'clear',
    },
    'Muscular Dystrophy (Discovered in the Cavalier King Charles Spaniel; CKCS-MD)': {
      trait: 'Muscular Dystrophy (Discovered in the Cavalier King Charles Spaniel; CKCS-MD)',
      result: 'clear',
    },
    'Muscular Dystrophy (Discovered in the Golden Retriever)': {
      trait: 'Muscular Dystrophy (Discovered in the Golden Retriever)',
      result: 'clear',
    },
    'Muscular Dystrophy, Ullrich Type (Discovered in the Landseer)': {
      trait: 'Muscular Dystrophy, Ullrich Type (Discovered in the Landseer)',
      result: 'clear',
    },
    'Muscular Hypertrophy (Double Muscling)': {
      trait: 'Muscular Hypertrophy (Double Muscling)',
      result: 'clear',
    },
    'Musladin-Lueke syndrome (MLS)': {
      trait: 'Musladin-Lueke syndrome (MLS)',
      result: 'clear',
    },
    'Myeloperoxidase Deficiency (Discovered in the Italian Hound)': {
      trait: 'Myeloperoxidase Deficiency (Discovered in the Italian Hound)',
      result: 'clear',
    },
    'Myotonia Congenita (Discovered in the Australian Cattle Dog)': {
      trait: 'Myotonia Congenita (Discovered in the Australian Cattle Dog)',
      result: 'clear',
    },
    'Myotonia Congenita (Discovered in the Labrador Retriever)': {
      trait: 'Myotonia Congenita (Discovered in the Labrador Retriever)',
      result: 'clear',
    },
    'Myotonia Congenita (Discovered in the Miniature Schnauzer)': {
      trait: 'Myotonia Congenita (Discovered in the Miniature Schnauzer)',
      result: 'clear',
    },
    'Myotubular Myopathy (Discovered in the Labrador Retriever)': {
      trait: 'Myotubular Myopathy (Discovered in the Labrador Retriever)',
      result: 'clear',
    },
    'Myotubular Myopathy (Discovered in the Rottweiler)': {
      trait: 'Myotubular Myopathy (Discovered in the Rottweiler)',
      result: 'clear',
    },
    'Myotubular Myopathy 1 (Boykin Spaniel Type)': {
      trait: 'Myotubular Myopathy 1 (Boykin Spaniel Type)',
      result: 'clear',
    },
    'Narcolepsy (Discovered in Dachshunds)': {
      trait: 'Narcolepsy (Discovered in Dachshunds)',
      result: 'clear',
    },
    'Narcolepsy (Discovered in the Labrador Retriever)': {
      trait: 'Narcolepsy (Discovered in the Labrador Retriever)',
      result: 'clear',
    },
    'Narcolepsy (Doberman Pinscher Type)': {
      trait: 'Narcolepsy (Doberman Pinscher Type)',
      result: 'clear',
    },
    'Neonatal Cerebellar Cortical Degeneration (SPTBN2-related; NCCD)': {
      trait: 'Neonatal Cerebellar Cortical Degeneration (SPTBN2-related; NCCD)',
      result: 'clear',
    },
    'Neonatal Encephalopathy with Seizures (NEWS)': {
      trait: 'Neonatal Encephalopathy with Seizures (NEWS)',
      result: 'clear',
    },
    'Neuroaxonal Dystrophy': {
      trait: 'Neuroaxonal Dystrophy',
      result: 'clear',
    },
    'Neuroaxonal Dystrophy (Discovered in the Papillon; PLA2G6-related; NAD)': {
      trait: 'Neuroaxonal Dystrophy (Discovered in the Papillon; PLA2G6-related; NAD)',
      result: 'clear',
    },
    'Neuroaxonal Dystrophy (Discovered in the Rottweiler; VPS11-related)': {
      trait: 'Neuroaxonal Dystrophy (Discovered in the Rottweiler; VPS11-related)',
      result: 'clear',
    },
    'Neuroaxonal Dystrophy (Discovered in the Spanish Water Dog; NAD)': {
      trait: 'Neuroaxonal Dystrophy (Discovered in the Spanish Water Dog; NAD)',
      result: 'clear',
    },
    'Neurodegeneration with Alpha-Mannosidase Deficiency and Cytoplasmic Vacuoles': {
      trait: 'Neurodegeneration with Alpha-Mannosidase Deficiency and Cytoplasmic Vacuoles',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis (Tibetan Terrier Type)': {
      trait: 'Neuronal Ceroid Lipofuscinosis (Tibetan Terrier Type)',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 1 (Discovered in the Dachshund; NCL1)': {
      trait: 'Neuronal Ceroid Lipofuscinosis 1 (Discovered in the Dachshund; NCL1)',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 10': {
      trait: 'Neuronal Ceroid Lipofuscinosis 10',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 12 (Discovered in the Australian Cattle Dog)': {
      trait: 'Neuronal Ceroid Lipofuscinosis 12 (Discovered in the Australian Cattle Dog)',
      result: 'clear',
    },

    'Neuronal Ceroid Lipofuscinosis 2': {
      trait: 'Neuronal Ceroid Lipofuscinosis 2',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 4A (Discovered in the American Staffordshire; NCL4A)': {
      trait: 'Neuronal Ceroid Lipofuscinosis 4A (Discovered in the American Staffordshire; NCL4A)',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 5 (Discovered in the Border Collie; NCL5)': {
      trait: 'Neuronal Ceroid Lipofuscinosis 5 (Discovered in the Border Collie; NCL5)',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 5 (Golden Retriever Type)': {
      trait: 'Neuronal Ceroid Lipofuscinosis 5 (Golden Retriever Type)',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 6': {
      trait: 'Neuronal Ceroid Lipofuscinosis 6',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 7 (Discovered in the Chinese Crested Dog; NCL7)': {
      trait: 'Neuronal Ceroid Lipofuscinosis 7 (Discovered in the Chinese Crested Dog; NCL7)',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 8 (Discovered in the Alpine Dachsbracke; NCL8)': {
      trait: 'Neuronal Ceroid Lipofuscinosis 8 (Discovered in the Alpine Dachsbracke; NCL8)',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 8 (Discovered in the Australian Shepherd; NCL8)': {
      trait: 'Neuronal Ceroid Lipofuscinosis 8 (Discovered in the Australian Shepherd; NCL8)',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 8 (Discovered in the English Setter; NCL8)': {
      trait: 'Neuronal Ceroid Lipofuscinosis 8 (Discovered in the English Setter; NCL8)',
      result: 'clear',
    },
    'Neuronal Ceroid Lipofuscinosis 8 (Discovered in the Saluki; NCL8)': {
      trait: 'Neuronal Ceroid Lipofuscinosis 8 (Discovered in the Saluki; NCL8)',
      result: 'clear',
    },
    'Nonsyndromic hearing loss (Discovered in the Rottweiler)': {
      trait: 'Nonsyndromic hearing loss (Discovered in the Rottweiler)',
      result: 'clear',
    },
    'Oculocutaneous Albinism (Doberman Pinscher Type)': {
      trait: 'Oculocutaneous Albinism (Doberman Pinscher Type)',
      result: 'clear',
    },
    'Oculocutaneous Albinism (Small Breed Type)': {
      trait: 'Oculocutaneous Albinism (Small Breed Type)',
      result: 'clear',
    },
    'Oculoskeletal Dysplasia (OSD2)': {
      trait: 'Oculoskeletal Dysplasia (OSD2)',
      result: 'clear',
    },
    'Osteochondrodysplasia (Discovered in the Miniature Poodle)': {
      trait: 'Osteochondrodysplasia (Discovered in the Miniature Poodle)',
      result: 'clear',
    },
    'Osteochondromatosis (Discovered in the American Staffordshire Terrier)': {
      trait: 'Osteochondromatosis (Discovered in the American Staffordshire Terrier)',
      result: 'clear',
    },
    'Osteogenesis Imperfecta (Discovered in the Beagle; COL1A2-related; OI)': {
      trait: 'Osteogenesis Imperfecta (Discovered in the Beagle; COL1A2-related; OI)',
      result: 'clear',
    },
    'Osteogenesis Imperfecta (Discovered in the Dachshund; OI)': {
      trait: 'Osteogenesis Imperfecta (Discovered in the Dachshund; OI)',
      result: 'clear',
    },
    'Osteogenesis Imperfecta (Golden Retriever Type)': {
      trait: 'Osteogenesis Imperfecta (Golden Retriever Type)',
      result: 'clear',
    },
    'Paroxysmal Dyskinesia (PIGN-related; PxD)': {
      trait: 'Paroxysmal Dyskinesia (PIGN-related; PxD)',
      result: 'clear',
    },
    'Persistent Müllerian Duct Syndrome (PMDS)': {
      trait: 'Persistent Müllerian Duct Syndrome (PMDS)',
      result: 'clear',
    },
    'Pituitary Dwarfism (Discovered in the Karelian Bear Dog; POU1F1-related)': {
      trait: 'Pituitary Dwarfism (Discovered in the Karelian Bear Dog; POU1F1-related)',
      result: 'clear',
    },
    'Pituitary-Dependent Hyperadrenocorticism (Discovered in Poodles)': {
      trait: 'Pituitary-Dependent Hyperadrenocorticism (Discovered in Poodles)',
      result: 'clear',
    },
    'Polycystic Kidney Disease (Discovered in the Bull Terrier)': {
      trait: 'Polycystic Kidney Disease (Discovered in the Bull Terrier)',
      result: 'clear',
    },
    'Polydactyly (Asian breeds)': {
      trait: 'Polydactyly (Asian breeds)',
      result: 'clear',
    },
    'Polydactyly (Common Variant)': {
      trait: 'Polydactyly (Common Variant)',
      result: 'clear',
    },
    'Polyneuropathy (Leonberger and Saint Bernard Type)': {
      trait: 'Polyneuropathy (Leonberger and Saint Bernard Type)',
      result: 'clear',
    },
    'Polyneuropathy with Ocular Abnormalities and Neuronal Vacuolation (DUP)': {
      trait: 'Polyneuropathy with Ocular Abnormalities and Neuronal Vacuolation (DUP)',
      result: 'clear',
    },
    'Polyneuropathy with Ocular Abnormalities and Neuronal Vacuolation (INS)': {
      trait: 'Polyneuropathy with Ocular Abnormalities and Neuronal Vacuolation (INS)',
      result: 'clear',
    },
    'Polyneuropathy with Ocular Abnormalities and Neuronal Vacuolation (POANV)': {
      trait: 'Polyneuropathy with Ocular Abnormalities and Neuronal Vacuolation (POANV)',
      result: 'clear',
    },
    'Pompe Disease': {
      trait: 'Pompe Disease',
      result: 'clear',
    },
    'Prekallikrein Deficiency': {
      trait: 'Prekallikrein Deficiency',
      result: 'clear',
    },

    'Primary Ciliary Dyskinesia (Discovered in the Alaskan Malamute; NME5-related; PCD)': {
      trait: 'Primary Ciliary Dyskinesia (Discovered in the Alaskan Malamute; NME5-related; PCD)',
      result: 'clear',
    },
    'Primary Ciliary Dyskinesia (Discovered in the O.E Sheepdog; CCDC39-related PCD)': {
      trait: 'Primary Ciliary Dyskinesia (Discovered in the O.E Sheepdog; CCDC39-related PCD)',
      result: 'clear',
    },
    'Primary Hyperoxaluria': {
      trait: 'Primary Hyperoxaluria',
      result: 'clear',
    },
    'Primary Lens Luxation (PLL)': {
      trait: 'Primary Lens Luxation (PLL)',
      result: 'clear',
    },
    'Primary Open Angle Glaucoma (ADAMTS17-related; POAG)': {
      trait: 'Primary Open Angle Glaucoma (ADAMTS17-related; POAG)',
      result: 'clear',
    },
    'Primary Open Angle Glaucoma (Basset Fauve de Bretagne Type)': {
      trait: 'Primary Open Angle Glaucoma (Basset Fauve de Bretagne Type)',
      result: 'clear',
    },
    'Primary Open Angle Glaucoma (Discovered in Petit Basset Griffon Vendeen)': {
      trait: 'Primary Open Angle Glaucoma (Discovered in Petit Basset Griffon Vendeen)',
      result: 'clear',
    },
    'Primary Open Angle Glaucoma (Discovered in the Beagle; POAG)': {
      trait: 'Primary Open Angle Glaucoma (Discovered in the Beagle; POAG)',
      result: 'clear',
    },
    'Primary Open Angle Glaucoma (Norwegian Elkhound Type)': {
      trait: 'Primary Open Angle Glaucoma (Norwegian Elkhound Type)',
      result: 'clear',
    },
    'Primary Open Angle Glaucoma and Lens Luxation (Discovered in the Chinese Shar-Pei)': {
      trait: 'Primary Open Angle Glaucoma and Lens Luxation (Discovered in the Chinese Shar-Pei)',
      result: 'clear',
    },
    'Progressive Early-Onset Cerebellar Ataxia (Discovered in Finnish Hound; SEL1L-related)': {
      trait: 'Progressive Early-Onset Cerebellar Ataxia (Discovered in Finnish Hound; SEL1L-related)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy (Discovered in the Basenji; SAG-related)': {
      trait: 'Progressive Retinal Atrophy (Discovered in the Basenji; SAG-related)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy (Discovered in the Giant Schnauzer; NECAP1-related)': {
      trait: 'Progressive Retinal Atrophy (Discovered in the Giant Schnauzer; NECAP1-related)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy (Discovered in the Golden Retriever; GR_PRA1)': {
      trait: 'Progressive Retinal Atrophy (Discovered in the Golden Retriever; GR_PRA1)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy (Discovered in the Golden Retriever; GR_PRA2)': {
      trait: 'Progressive Retinal Atrophy (Discovered in the Golden Retriever; GR_PRA2)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy (Discovered in the Lapponian Herder; IFT122-PRA)': {
      trait: 'Progressive Retinal Atrophy (Discovered in the Lapponian Herder; IFT122-PRA)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy (Discovered in the Lhasa Apso; IMPG2-related PRA4)': {
      trait: 'Progressive Retinal Atrophy (Discovered in the Lhasa Apso; IMPG2-related PRA4)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy (Discovered in the Papillon; CNGB1-related; PAP1_PRA)': {
      trait: 'Progressive Retinal Atrophy (Discovered in the Papillon; CNGB1-related; PAP1_PRA)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy (Discovered in the Shetland Sheepdog; CNGA1-PRA)': {
      trait: 'Progressive Retinal Atrophy (Discovered in the Shetland Sheepdog; CNGA1-PRA)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy (Discovered in the Swedish Vallhund; MERTK-related)': {
      trait: 'Progressive Retinal Atrophy (Discovered in the Swedish Vallhund; MERTK-related)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy 1 (Discovered in the Italian Greyhound; IG-PRA1)': {
      trait: 'Progressive Retinal Atrophy 1 (Discovered in the Italian Greyhound; IG-PRA1)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy, Early Onset (Spanish Water Dog Type)': {
      trait: 'Progressive Retinal Atrophy, Early Onset (Spanish Water Dog Type)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy, Generalized (Discovered in the Schapendoes)': {
      trait: 'Progressive Retinal Atrophy, Generalized (Discovered in the Schapendoes)',
      result: 'clear',
    },
    'Progressive Retinal Atrophy, Type III (PRA type III; FAM161A-related)': {
      trait: 'Progressive Retinal Atrophy, Type III (PRA type III; FAM161A-related)',
      result: 'clear',
    },
    'Progressive Rod-Cone Degeneration (prcd-PRA)': {
      trait: 'Progressive Rod-Cone Degeneration (prcd-PRA)',
      result: 'clear',
    },
    'Protein Losing Nephropathy (KIRREL2-Related)': {
      trait: 'Protein Losing Nephropathy (KIRREL2-Related)',
      result: 'clear',
    },
    'Pyruvate Dehydrogenase Phosphatase 1 (PDP1) Deficiency': {
      trait: 'Pyruvate Dehydrogenase Phosphatase 1 (PDP1) Deficiency',
      result: 'clear',
    },
    'Pyruvate Kinase (PK) Deficiency (Discovered in the Basenji)': {
      trait: 'Pyruvate Kinase (PK) Deficiency (Discovered in the Basenji)',
      result: 'clear',
    },
    'Pyruvate Kinase (PK) Deficiency (Discovered in the Beagle)': {
      trait: 'Pyruvate Kinase (PK) Deficiency (Discovered in the Beagle)',
      result: 'clear',
    },
    'Pyruvate Kinase (PK) Deficiency (Discovered in the Pug)': {
      trait: 'Pyruvate Kinase (PK) Deficiency (Discovered in the Pug)',
      result: 'clear',
    },
    'Pyruvate Kinase Deficiency (Terrier Type)': {
      trait: 'Pyruvate Kinase Deficiency (Terrier Type)',
      result: 'clear',
    },
    'Recurrent Inflammatory Pulmonary Disease (Discovered in the Rough Collie)': {
      trait: 'Recurrent Inflammatory Pulmonary Disease (Discovered in the Rough Collie)',
      result: 'clear',
    },
    'Renal Cystadenocarcinoma and Nodular Dermatofibrosis (RCND)': {
      trait: 'Renal Cystadenocarcinoma and Nodular Dermatofibrosis (RCND)',
      result: 'clear',
    },
    'Rod-Cone Dysplasia 1 (Discovered in the Irish Setter; rcd1)': {
      trait: 'Rod-Cone Dysplasia 1 (Discovered in the Irish Setter; rcd1)',
      result: 'clear',
    },
    'Rod-Cone Dysplasia 1a (Discovered in the Sloughi; rcd1a)': {
      trait: 'Rod-Cone Dysplasia 1a (Discovered in the Sloughi; rcd1a)',
      result: 'clear',
    },
    'Rod-Cone Dysplasia 3 (Discovered in the Cardigan Welsh Corgi; rcd3)': {
      trait: 'Rod-Cone Dysplasia 3 (Discovered in the Cardigan Welsh Corgi; rcd3)',
      result: 'clear',
    },
    'Sensory Neuropathy (Discovered in the Border Collie)': {
      trait: 'Sensory Neuropathy (Discovered in the Border Collie)',
      result: 'clear',
    },
    'Severe Combined Immunodeficiency (Discovered in the Frisian Water Dog)': {
      trait: 'Severe Combined Immunodeficiency (Discovered in the Frisian Water Dog)',
      result: 'clear',
    },
    'Shar-Pei Autoinflammatory Disease (SPAID)': {
      trait: 'Shar-Pei Autoinflammatory Disease (SPAID)',
      result: 'clear',
    },
    'Skeletal Dysplasia 2 (SD2)': {
      trait: 'Skeletal Dysplasia 2 (SD2)',
      result: 'clear',
    },
    'Spinal Dysraphism': {
      trait: 'Spinal Dysraphism',
      result: 'clear',
    },
    'Spinocerebellar Ataxia (Late-Onset Ataxia; CAPN1-related; SCA/LOA)': {
      trait: 'Spinocerebellar Ataxia (Late-Onset Ataxia; CAPN1-related; SCA/LOA)',
      result: 'clear',
    },
    'Spinocerebellar Ataxia with Myokymia and/or Seizures (KCNJ10-related; SCA)': {
      trait: 'Spinocerebellar Ataxia with Myokymia and/or Seizures (KCNJ10-related; SCA)',
      result: 'clear',
    },
    'Spondylocostal Dysostosis (Discovered in the Miniature Schnauzer)': {
      trait: 'Spondylocostal Dysostosis (Discovered in the Miniature Schnauzer)',
      result: 'clear',
    },
    'Spongy Degeneration with Cerebellar Ataxia (KCNJ10-related; SDCA1)': {
      trait: 'Spongy Degeneration with Cerebellar Ataxia (KCNJ10-related; SDCA1)',
      result: 'clear',
    },
    'Stargardt Disease (Discovered in the Labrador Retriever)': {
      trait: 'Stargardt Disease (Discovered in the Labrador Retriever)',
      result: 'clear',
    },
    'Subacute Necrotizing Encephalopathy (Yorkshire Terrier Type)': {
      trait: 'Subacute Necrotizing Encephalopathy (Yorkshire Terrier Type)',
      result: 'clear',
    },
    'Success in Assistance Dog Training Programs': {
      trait: 'Success in Assistance Dog Training Programs',
      result: 'clear',
    },
    'Thrombopathia (Discovered in the Basset Hound)': {
      trait: 'Thrombopathia (Discovered in the Basset Hound)',
      result: 'clear',
    },
    'Thrombopathia (Discovered in the Eskimo Spitz)': {
      trait: 'Thrombopathia (Discovered in the Eskimo Spitz)',
      result: 'clear',
    },
    'Thrombopathia (Discovered in the Landseer)': {
      trait: 'Thrombopathia (Discovered in the Landseer)',
      result: 'clear',
    },
    'Trapped Neutrophil Syndrome (TNS)': {
      trait: 'Trapped Neutrophil Syndrome (TNS)',
      result: 'clear',
    },
    'Ullrich Congenital Muscular Dystrophy (Labrador Retriever Type 1)': {
      trait: 'Ullrich Congenital Muscular Dystrophy (Labrador Retriever Type 1)',
      result: 'clear',
    },
    'Ullrich Congenital Muscular Dystrophy (Labrador Retriever Type 2)': {
      trait: 'Ullrich Congenital Muscular Dystrophy (Labrador Retriever Type 2)',
      result: 'clear',
    },
    'Van den Ende-Gupta Syndrome (VDEGS)': {
      trait: 'Van den Ende-Gupta Syndrome (VDEGS)',
      result: 'clear',
    },
    "Von Willebrand's Disease, Type 1": {
      trait: "Von Willebrand's Disease, Type 1",
      result: 'clear',
    },
    "Von Willebrand's Disease, Type 2": {
      trait: "Von Willebrand's Disease, Type 2",
      result: 'clear',
    },
    "Von Willebrand's Disease, Type 3 (Discovered in the Kooiker Hound)": {
      trait: "Von Willebrand's Disease, Type 3 (Discovered in the Kooiker Hound)",
      result: 'clear',
    },
    "Von Willebrand's Disease, Type 3 (Discovered in the Scottish Terrier)": {
      trait: "Von Willebrand's Disease, Type 3 (Discovered in the Scottish Terrier)",
      result: 'clear',
    },
    "Von Willebrand's Disease, Type 3 (Discovered in the Shetland Sheepdog)": {
      trait: "Von Willebrand's Disease, Type 3 (Discovered in the Shetland Sheepdog)",
      result: 'clear',
    },
    'X-Linked Ectodermal Dysplasia (XHED)': {
      trait: 'X-Linked Ectodermal Dysplasia (XHED)',
      result: 'clear',
    },
    'X-Linked Hereditary Nephropathy (Discovered in the Navasota Dog; XLHN)': {
      trait: 'X-Linked Hereditary Nephropathy (Discovered in the Navasota Dog; XLHN)',
      result: 'clear',
    },
    'X-Linked Hereditary Nephropathy (Discovered in the Samoyed; XLHN)': {
      trait: 'X-Linked Hereditary Nephropathy (Discovered in the Samoyed; XLHN)',
      result: 'clear',
    },
    'X-Linked Progressive Retinal Atrophy 1 (XLPRA1)': {
      trait: 'X-Linked Progressive Retinal Atrophy 1 (XLPRA1)',
      result: 'clear',
    },

    'X-Linked Progressive Retinal Atrophy 2 (XLPRA2; Type A PRA)': {
      trait: 'X-Linked Progressive Retinal Atrophy 2 (XLPRA2; Type A PRA)',
      result: 'clear',
    },
    'X-Linked Severe Combined Immunodeficiency (Discovered in the Basset Hound; XSCID)': {
      trait: 'X-Linked Severe Combined Immunodeficiency (Discovered in the Basset Hound; XSCID)',
      result: 'clear',
    },
    'X-Linked Severe Combined Immunodeficiency (Discovered in the C.W Corgi; XSCID)': {
      trait: 'X-Linked Severe Combined Immunodeficiency (Discovered in the C.W Corgi; XSCID)',
      result: 'clear',
    },
    'X-Linked Tremors (Discovered in the English Springer Spaniel)': {
      trait: 'X-Linked Tremors (Discovered in the English Springer Spaniel)',
      result: 'clear',
    },
    'Xanthinuria, Type 1 (Discovered in mixed breed dogs)': {
      trait: 'Xanthinuria, Type 1 (Discovered in mixed breed dogs)',
      result: 'clear',
    },
    'Xanthinuria, Type II (Discovered in the Cavalier King Charles Spaniel)': {
      trait: 'Xanthinuria, Type II (Discovered in the Cavalier King Charles Spaniel)',
      result: 'clear',
    },
    'Xanthinuria, Type II (Discovered in the Toy Manchester Terrier)': {
      trait: 'Xanthinuria, Type II (Discovered in the Toy Manchester Terrier)',
      result: 'clear',
    },
    'Canine Degenerative Myelopathy, DM, SOD1B': {
      trait: 'Canine Degenerative Myelopathy, DM, SOD1B',
      result: 'nocall',
    },
    'Copper Storage Disease': {
      trait: 'Copper Storage Disease',
      result: 'nocall',
    },
    'Degenerative Myelopathy Early-Onset Risk Modifier (Pembroke Welsh Corgi Type)': {
      trait: 'Degenerative Myelopathy Early-Onset Risk Modifier (Pembroke Welsh Corgi Type)',
      result: 'nocall',
    },
    'Intestinal Cobalamin Malabsorption (Australian Shepherd Type)': {
      trait: 'Intestinal Cobalamin Malabsorption (Australian Shepherd Type)',
      result: 'nocall',
    },
    'Lafora Disease': {
      trait: 'Lafora Disease',
      result: 'nocall',
    },
    'Mucopolysaccharidosis I (Boston Terrier Type)': {
      trait: 'Mucopolysaccharidosis I (Boston Terrier Type)',
      result: 'nocall',
    },
    'Pituitary Dwarfism (Shepherd Type)': {
      trait: 'Pituitary Dwarfism (Shepherd Type)',
      result: 'nocall',
    },
    'Primary Open Angle Glaucoma (Discovered in Basset Fauve de Bretagne)': {
      trait: 'Primary Open Angle Glaucoma (Discovered in Basset Fauve de Bretagne)',
      result: 'nocall',
    },
    'Progressive Retinal Atrophy, Cone-Rod Dystrophy 3 (Glen of Imaal Terrier Type)': {
      trait: 'Progressive Retinal Atrophy, Cone-Rod Dystrophy 3 (Glen of Imaal Terrier Type)',
      result: 'nocall',
    },
    'Progressive Retinal Atrophy, Rod-Cone Dysplasia 2 (Collie Type)': {
      trait: 'Progressive Retinal Atrophy, Rod-Cone Dysplasia 2 (Collie Type)',
      result: 'nocall',
    },
    'Progressive Retinal Atrophy, Rod-Cone Dysplasia 4': {
      trait: 'Progressive Retinal Atrophy, Rod-Cone Dysplasia 4',
      result: 'nocall',
    },
    'Retinal Dysplasia/Oculoskeletal Dysplasia 1': {
      trait: 'Retinal Dysplasia/Oculoskeletal Dysplasia 1',
      result: 'nocall',
    },
  },
  physicalTraits: {
    'Body Size (IGF1)': {
      trait: 'Body Size (IGF1)',
      genotype: 'I/I',
      notes: 'Medium to Large',
    },
    'Body Size (IGF1R)': {
      trait: 'Body Size (IGF1R)',
      genotype: 'G/G',
      notes: 'Medium to Large',
    },
    'Body Size (IGF1_Sine)': {
      trait: 'Body Size (IGF1_Sine)',
      genotype: 'ALT',
      notes: 'Small',
    },
    'Body Size (IGF2BP2)': {
      trait: 'Body Size (IGF2BP2)',
      genotype: 'G/G',
      notes: 'Medium to Large',
    },
    'Body Size (SMAD2)': {
      trait: 'Body Size (SMAD2)',
      genotype: 'ALT',
      notes: 'Small',
    },
    'Body Size (STC2)': {
      trait: 'Body Size (STC2)',
      genotype: 'T/T',
      notes: 'Medium to Large',
    },
    'Curly Tail (ACE)': {
      trait: 'Curly Tail (ACE)',
      genotype: 'G/A',
      notes: 'Intermediate',
    },
    'Curvy tail': {
      trait: 'Curvy tail',
      genotype: 'C/C',
      notes: 'Curly',
    },
    'Floppy Ears (GRIP1)': {
      trait: 'Floppy Ears (GRIP1)',
      genotype: 'T/C',
      notes: 'Intermediate',
    },
    'Floppy Ears (MSRB3_E_1)': {
      trait: 'Floppy Ears (MSRB3_E_1)',
      genotype: 'T/T',
      notes: 'Prick',
    },
    'Floppy Ears (MSRB3_E_2)': {
      trait: 'Floppy Ears (MSRB3_E_2)',
      genotype: 'C/C',
      notes: 'Prick',
    },
    'Head Shape (BMP3)': {
      trait: 'Head Shape (BMP3)',
      genotype: 'G/G',
      notes: 'Non-brachycephalic',
    },
    'Head Shape (SMOC2)': {
      trait: 'Head Shape (SMOC2)',
      genotype: 'G/G',
      notes: 'Non-brachycephalic',
    },
    'Head Shape (THBS2)': {
      trait: 'Head Shape (THBS2)',
      genotype: 'T/C',
      notes: 'Intermediate',
    },
    'Leg Length (SEMA3c_1)': {
      trait: 'Leg Length (SEMA3c_1)',
      genotype: 'G/G',
      notes: 'Standard-size',
    },
    'Leg Length (SEMA3c_2)': {
      trait: 'Leg Length (SEMA3c_2)',
      genotype: 'G/G',
      notes: 'Standard-size',
    },
    'Leg Length (SEMA3c_3)': {
      trait: 'Leg Length (SEMA3c_3)',
      genotype: 'G/G',
      notes: 'Standard-size',
    },
    'Leg Length (SEMA3c_4)': {
      trait: 'Leg Length (SEMA3c_4)',
      genotype: 'C/T',
      notes: 'Intermediate',
    },
    'Natural Bobtail (T Locus)': {
      trait: 'Natural Bobtail (T Locus)',
      genotype: 'G/G',
      notes: 'Non-BobTail',
    },
    'ST Locus (Screw Tail)': {
      trait: 'ST Locus (Screw Tail)',
      genotype: 'NoCall',
      notes: 'NoCall',
    },
    'Predicted Body Size': {
      trait: 'Predicted Body Size',
      genotype: 'Medium to Large',
    },
    'Predicted Leg Length': {
      trait: 'Predicted Leg Length',
      genotype: 'Standard-size',
    },
    'Predicted Ear Form': {
      trait: 'Predicted Ear Form',
      genotype: 'Prick',
    },
    'Predicted Head Shape': {
      trait: 'Predicted Head Shape',
      genotype: 'Non-brachycephalic',
    },
  },
}

export const getMazikeenDiagnostics = () => {
  return mazikeen
}
