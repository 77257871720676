import React from 'react'
import { FaPaw } from 'react-icons/fa6'

interface PawRatingProps {
  value: number
}

export function PawRating({ value = 0 }: PawRatingProps) {
  return (
    <div className="grid grid-cols-5 gap-2 max-w-[250px] justify-self-center">
      {Array.from({ length: 5 }, (_, i) => (
        <FaPaw key={i} className={i < value ? '' : 'text-gray-300'} />
      ))}
    </div>
  )
}
