import React, { SyntheticEvent, useRef, useState } from 'react'
import { FaCloudUploadAlt } from 'react-icons/fa'
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, { Crop, centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop'
import { canvasPreview } from '../utils/canvasPreview.ts'
import { DogButton } from './DogButton.tsx'
import { compressImage } from '../utils/base64-to-file.ts'
import { blobToBase64 } from '../utils/blob-to-base64.ts'

const ASPECT_RATIO = 1.5
const MIN_WIDTH = 216

export const AvatarCropper = ({updateAvatar, closeModal}:any) => {
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)

  const [avatarSrc, setAvatarSrc] = useState('')
  const [error, setError] = useState('')
  const [crop, setCrop] = useState<Crop>()

  const onSelectFile = (e: any) => {
    const file = e.target.files?.[0]
    if (!file) return

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const imageUrl = reader.result?.toString() || ''
      const imageElement = new Image()
      imageElement.src = imageUrl

      imageElement.addEventListener('load', (e) => {
        if (error) setError('')
        const { naturalWidth, naturalHeight } = e.currentTarget as HTMLImageElement
        if (naturalWidth < 216 || naturalHeight < 144) {
          setError('Image resolution must be at least 216x144 pixels.')
          setAvatarSrc('')
          return
        }
      })
      setAvatarSrc(imageUrl)
    })
    reader.readAsDataURL(file)
  }

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement, Event>): void => {
    const { width, height } = e.currentTarget

    // Ensures relative crop does not go below MIN_WIDTH
    const adjustedWidth = (MIN_WIDTH / width) * 100

    const initialCrop = makeAspectCrop({ unit: '%', width: adjustedWidth }, ASPECT_RATIO, width, height)
    setCrop(centerCrop(initialCrop, width, height))
  }

  return (
    <>
      <div className="relative grid justify-center">
        <label
          htmlFor="avatar-upload"
          className="flex w-fit text-sm top-1 left-1 mb-4 mx-auto bg-edxred-500 hover:bg-edxred-700 text-white rounded-full px-4 py-2 cursor-pointer"
        >
          <span className="flex gap-2 items-center">
            <FaCloudUploadAlt size={20} /> Choose image
          </span>
        </label>
        <input id="avatar-upload" type="file" accept="image/*" onChange={onSelectFile} className="hidden" />
        {error && <p className="text-red-500 text-xs">{error}</p>}
        {avatarSrc && (
          <div className='grid gap-4'>
            <ReactCrop
              keepSelection
              crop={crop}
              aspect={ASPECT_RATIO}
              // minWidth={MIN_WIDTH}
              onChange={(_, percentCrop) => {
                setCrop(percentCrop)
              }}
            >
              <img className="!max-h-[60vh]" ref={imgRef} src={avatarSrc} alt="Upload" draggable={false} onLoad={onImageLoad} />
            </ReactCrop>
            <DogButton
              classNames="text-white hover:bg-edxred-700"
              variant="brand"
              onClickFn={async () => {
                if (!imgRef.current || !previewCanvasRef.current) return
                
                canvasPreview(
                  imgRef.current, // HTMLImageElement
                  previewCanvasRef.current, // HTMLCanvasElement
                  convertToPixelCrop(crop!, imgRef.current['width'], imgRef.current['height']),
                );

                const dataUrl = (previewCanvasRef.current as any).toDataURL();
                const blob = await compressImage(dataUrl);
                const base64 = await blobToBase64(blob as Blob);
                updateAvatar(base64);
                closeModal();
              }}
            >
              Save Selection
            </DogButton>
          </div>
        )}
        {crop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              position: 'absolute',
              objectFit: 'contain',
              width: 216,
              height: 144,
            }}
          />
        )}
      </div>
    </>
  )
}
