import urlJoin from 'url-join'
import { Environment } from '../../config/environment.ts'

/**
 * Make an image URL for an image path from the EDX API
 * @param edxUrlPath {string | null | undefined} - The image path from the EDX API, ie: 'dog/images/13/db45122f-b37f-4ae3-a9a2-e656d7bb18cf..png'
 *
 * @returns {string} - The full image URL
 */
export function makeEdxImageUrl(edxUrlPath: string | null | undefined, useLowResolution: boolean = false) {
  if (!edxUrlPath) {
    return ''
  }

  if (useLowResolution)
    edxUrlPath = edxUrlPath.replace('.max.jpg', '.min.jpg');

  if (edxUrlPath.startsWith('http')) {
    return edxUrlPath
  }

  return urlJoin(Environment.IMAGES_BASE_URL, edxUrlPath)
}
