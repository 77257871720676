import { FaCircleInfo } from 'react-icons/fa6'

interface BehaviorSliderProps {
  result: 'unlikely' | 'possible' | 'likely'
}

const resultStepMap = {
  unlikely: 0,
  possible: 1,
  likely: 2,
}

export function BehaviorSlider({ result }: BehaviorSliderProps) {
  const resultStep: number = resultStepMap[result]
  return (
    <div className="flex flex-col gap-2">
      <div className="w-full border border-edxred-500 relative rounded-lg px-1">
        <div
          className="min-w-0 bg-edxred-500 w-full h-2 m-1  rounded-lg"
          style={{ width: '50%', marginLeft: `${resultStep * 25}%` }}
        ></div>
      </div>
    </div>
  )
}
