import React from 'react'
import { Badge, Card, CardContent, CardHeader, CardTitle } from '@edx/react-common'
import { useDiagnosticsState } from '../../../state-diagnostics.ts'
import { HealthIcon } from './HealthIcon/index.tsx'
import { DogCard } from '../../../../../components/DogCard.tsx'
import { useDogProfileState } from '../../../state.ts'

interface DiagnosticsHealthCardProps {}

export function DiagnosticsHealthCard({}: DiagnosticsHealthCardProps) {
  const diagnostics = useDogProfileState((state) => state.diagnosticsDetail)
  const healthTraits = []
  for (const key in diagnostics.healthTraits) {
    healthTraits.push(diagnostics.healthTraits[key])
  }

  return (
    <DogCard>
      <CardHeader>
        <CardTitle className="text-edxred-500 font-montserrat text-lg uppercase">Health Traits</CardTitle>
      </CardHeader>

      <div className="p-6 pt-0">
        <hr />
      </div>

      <CardContent>
        <div className="grid grid-cols-2">
          <div className="col-span-2 border rounded-t-lg text-lg p-2 px-4 bg-dogred-400 text-white font-semibold">
            Notable Health Traits
          </div>
          {healthTraits.slice(0, 3).map((trait) => {
            return (
              <>
                <div className="border border-t-0 border-r-0 p-4 [&:nth-last-child(2)]:rounded-bl-lg">
                  <h4 className="text-base">{trait.trait}</h4>
                </div>
                <div className="border border-t-0 p-4 [&:nth-child(2)]:rounded-tr-lg last:rounded-br-lg">
                  {trait.notes}
                </div>
              </>
            )
          })}
        </div>

        <div className="grid grid-cols-[54%,14%,32%] mt-6 drop-shadow-sm text-sm">
          {healthTraits.slice(3).map((trait, index) => (
            <>
              <div className="border border-t-0 border-r-0 p-2 first:border-t-[1px] first:rounded-tl-lg [&:nth-last-child(3)]:rounded-bl-lg">
                <h4 className="font-semibold block">{trait.trait}</h4>
              </div>
              <div className="place-content-center border border-t-0 border-r-0 border-l-0 [&:nth-child(2)]:border-t-[1px] pr-2">
                <Badge className="bg-dogred-400 float-right capitalize">
                  {trait.result}
                  <span className="pl-2">
                    <HealthIcon result={trait.result} />
                  </span>
                </Badge>
              </div>
              <div className="border border-t-0 p-2 [&:nth-child(3)]:border-t-[1px] [&:nth-child(3)]:rounded-tr-lg last:rounded-br-lg">
                {trait.notes}
              </div>
            </>
          ))}
        </div>
      </CardContent>
    </DogCard>
  )
}
