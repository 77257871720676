import React, { useEffect, useState } from 'react'
import { cn, LoadingSpinner, Tabs, TabsContent } from '@edx/react-common'
import { BiChevronLeft } from 'react-icons/bi'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { DogProfileCard } from './components/DogProfileCard'
import { DiagnosticsBehaviorCard } from './components/Diagnostics/Behavior'
import { DiagnosticsPhysicalCard } from './components/Diagnostics/Physical'
import { DiagnosticsCoatCard } from './components/Diagnostics/Coat'
import { DiagnosticsHealthCard } from './components/Diagnostics/Health'
import { DiagnosticsReportDialog } from '../../components/DiagnosticsReportDialog.tsx'
import { DogProfileTabs, DogResourcesTabs, useDogProfileState } from './state.ts'
import { ResourcesCard } from './components/Resources'
import { ProductDetailDialog } from './components/Products/ProductDetailDialog'
import { ServiceDetailDialog } from './components/Services/ServiceDetailDialog'
import { FamilyTreeCard } from './components/Ancestry/FamilyTreeCard.tsx'
import { DogsLikeMeCard } from './components/Ancestry/DogsLikeMeCard.tsx'
import { DogProfileNav } from './components/DogProfileNav.tsx'

// // Mock data for the dog object
// const dogData = {
//   name: 'Opal',
//   behavior: [
//     {
//       trait: 'Barking',
//       likelihood: 'Likely',
//       description:
//         'Opal likely barks persistently when alarmed or excited. Check out a few products that might help out you and Opal in the future.',
//     },
//     {
//       trait: 'Separation Anxiety',
//       likelihood: 'Possible',
//       description:
//         'Opal is more likely to vocalize or show destructive behavior when separated from her owner, displaying signs of anxiety like restlessness, loss of appetite, trembling, and excessive drooling. Check out a few of our calming product to help Opal in the future!',
//     },
//     {
//       trait: 'Chasing',
//       likelihood: 'Unlikely',
//       description: 'Opal is unlikely to chase after moving objects or animals.',
//     },
//     // ... more behavior traits
//   ],
//   physicalTraits: [
//     { trait: 'Curly Tail (ACE)', value: 'Intermediate' },
//     { trait: 'Curvy tail', value: 'Curly' },
//     { trait: 'Natural Bobtail (T Locus)', value: 'Non-BobTail' },
//     // ... more physical traits
//   ],
//   coatTraits: [
//     { trait: 'A locus (Agouti)', value: 'Unknown Combination', likelihood: 'Possible' },
//     { trait: 'As Locus (Saddle Tan)', value: 'Saddle Tan (non saddle Carrier)', likelihood: 'Likely' },
//     { trait: 'B Locus (Brown)', value: 'Black coat, nose and pads', likelihood: 'Unlikely' },
//     // ... more coat traits
//   ],
//   health: [
//     {
//       trait: 'Copper Toxicosis (Labrador Retriever Type)',
//       result: 'Affected',
//       note: 'One Variant Detected: Animal Possibly/Likely Affected',
//     },
//     {
//       trait: 'Cardiomyopathy and Juvenile Mortality',
//       result: 'Carrier',
//       note: 'One Variant Detected: Animal Unlikely Affected',
//     },
//     {
//       trait: 'Copper Toxicosis Protective Mutation (Labrador Retriever Type)',
//       result: 'Carrier',
//       note: 'One Variant Detected: Animal Unlikely Affected',
//     },
//     // ... more health traits
//   ],
// }

export function DogProfileScreen() {
  const params = useParams<{
    dogId: string
  }>()
  const navigate = useNavigate()
  const state = useDogProfileState()
  const [showDiagnosticsReportDialog, setShowDiagnosticsReportDialog] = useState(false)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const maxCardHeight = 700

  useEffect(() => {
    state.actions.loadDog(+params.dogId!)
  }, [params.dogId])

  const dog = state.dog

  if (state.isLoading) {
    return (
      <div className="w-full">
        <LoadingSpinner overlay size={52} />
      </div>
    )
  }

  if (!dog) {
    navigate('/')
    return null
  }

  const showProductDetailDialog = state.resources.products
    .find((product) => product.productList.some((product) => product.productId === state.resources.viewProductId))
    ?.productList.find((product) => product.productId === state.resources.viewProductId)

  const showServiceDetailDialog = state.resources.services
    .find((service) => service.serviceList.some((service) => service.serviceId === state.resources.viewServiceId))
    ?.serviceList.find((service) => service.serviceId === state.resources.viewServiceId)

  return (
    <div className="flex flex-col overflow-scroll" ref={containerRef}>
      <div className="w-full max-w-[1400px]">
        <DogProfileNav />
      </div>

      <div className="mx-auto py-4 flex gap-2 xl:gap-8 w-full max-w-[1400px] relative">
        <div>
          <DogProfileCard
            dog={dog}
            onClickDiagnosticReport={() => {
              setShowDiagnosticsReportDialog(true)
            }}
            onClickRecommendedProducts={() => {
              state.actions.setActiveTab(DogProfileTabs.Resources)
              state.resources.actions.setActiveTab(DogResourcesTabs.Products)
            }}
            onClickRecommendedServices={() => {
              state.actions.setActiveTab(DogProfileTabs.Resources)
              state.resources.actions.setActiveTab(DogResourcesTabs.Services)
            }}
          />
        </div>

        <div className="flex flex-col w-full flex-1">
          <Tabs className="w-full" value={state.activeTab}>
            <TabsContent value={DogProfileTabs.Behavior}>
              <DiagnosticsBehaviorCard />
            </TabsContent>

            <TabsContent value={DogProfileTabs.Physical}>
              <DiagnosticsPhysicalCard />
            </TabsContent>

            <TabsContent value={DogProfileTabs.Coat}>
              <DiagnosticsCoatCard />
            </TabsContent>

            <TabsContent value={DogProfileTabs.Health}>
              <DiagnosticsHealthCard />
            </TabsContent>

            <TabsContent value={DogProfileTabs.FindMyPack}>
              <FamilyTreeCard />
            </TabsContent>

            <TabsContent value={DogProfileTabs.DogsLikeMe}>
              <DogsLikeMeCard />
            </TabsContent>

            <TabsContent value={DogProfileTabs.Resources}>
              <ResourcesCard />

              {showProductDetailDialog && (
                <ProductDetailDialog
                  onClose={() => state.resources.actions.setViewProductId('')}
                  product={showProductDetailDialog}
                />
              )}

              {showServiceDetailDialog && (
                <ServiceDetailDialog
                  onClose={() => state.resources.actions.setViewServiceId('')}
                  service={showServiceDetailDialog}
                />
              )}
            </TabsContent>
          </Tabs>
        </div>
      </div>

      {showDiagnosticsReportDialog && state.dog.diagnosticsReportUrl && (
        <DiagnosticsReportDialog
          diagnosticsReportUrl={state.dog.diagnosticsReportUrl}
          onClose={() => setShowDiagnosticsReportDialog(false)}
        />
      )}
    </div>
  )
}
