import { FaClipboardQuestion } from "react-icons/fa6"
import dnaClear from "./dna_clear.png"
import dnaCarrier from "./dna_carrier.png"
import dnaAffected from "./dna_affected.png"

interface HealthIconProps {
    result: 'nocall' | 'clear' | 'carrier' | 'affected'
}

const resultToIcon = {
    nocall: <FaClipboardQuestion color="white" size={16}/>,
    clear: <img width={14} src={dnaClear}/>,
    carrier: <img width={14} src={dnaCarrier}/>,
    affected: <img width={14} src={dnaAffected}/>,
}

export function HealthIcon({result}: HealthIconProps) {
    return resultToIcon[result];
}