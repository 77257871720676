import React, { useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@edx/react-common'
import { CircularGroup } from './components/CircularGroup.tsx'
import { DogCard } from '../../../../components/DogCard.tsx'

interface DogsLikeMeCardProps {}

export function DogsLikeMeCard({}: DogsLikeMeCardProps) {
  const [showPreview, setShowPreview] = useState(false)

  return (
    <DogCard>
      {!showPreview && (
        <div className="absolute top-0 left-0 right-0 w-full h-full bg-white/30 z-10 backdrop-blur-sm flex flex-col items-center pt-32">
          <div className="text-edxred-500 text-3xl font-bold">Coming Soon</div>
          <div
            className="font-light text-lg text-gray-600 cursor-pointer hover:text-white hover:bg-edxred-500 rounded px-2 hover:font-bold"
            onClick={() => setShowPreview(!showPreview)}
          >
            Preview
          </div>
        </div>
      )}
      <CardHeader>
        <CardTitle className="text-edxred-500 font-montserrat text-lg uppercase">Dogs Like Me</CardTitle>
      </CardHeader>

      <CardContent className="h-screen">
        <CircularGroup />
      </CardContent>
    </DogCard>
  )
}
