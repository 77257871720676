import { create } from 'zustand'

export type AppState = {
  isDemo: boolean
  setIsDemo: (isDemo: boolean) => void
}

export const useAppState = create<AppState>((set) => ({
  isDemo: false,
  setIsDemo: (isDemo) => set({ isDemo }),
}))
