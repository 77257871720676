import { Heart, Share, CheckCircle, ChevronRight, Expand } from 'lucide-react'
import { Badge, Button, CardContent, CardHeader, CardTitle } from '@edx/react-common'
import React from 'react'
import dayjs from 'dayjs'
import { makeEdxImageUrl } from '../../../../utils/make-edx-image-url.ts'
import { DogDetail } from '../../../../services/edx-api.ts'
import { FaChevronRight, FaDownload, FaFilePdf, FaUsersViewfinder } from 'react-icons/fa6'
import { DogButton } from '../../../../components/DogButton.tsx'
import { MdViewAgenda, MdViewHeadline } from 'react-icons/md'

export interface DogProfileCardProps {
  dog: DogDetail
  onClickDiagnosticReport?: () => void
  onClickRecommendedProducts?: () => void
  onClickRecommendedServices?: () => void
}

export function DogProfileCard({
  dog,
  onClickDiagnosticReport,
  onClickRecommendedProducts,
  onClickRecommendedServices,
}: DogProfileCardProps) {
  return (
    <div className="bg-gradient-to-b from-doggradient-300/80 to-doggradient-100/80 shadow-md border-2 border-white backdrop-blur-[10px] rounded-3xl px-4 w-full max-w-[500px] flex flex-col relative h-fit">
      <CardHeader className="flex justify-between items-center flex-row">
        <CardTitle className="text-4xl font-bold font-montserrat text-red-800 min-w-0 text-ellipsis truncate">
          {dog?.name}
        </CardTitle>

        <img src="/us_flag.png" alt="US Flag" className="h-6" />
      </CardHeader>

      <CardContent className="space-y-4">
        <div className="flex justify-between items-center space-x-2">
          <div className="flex-1 text-lg truncate">
            {[dog?.dateOfBirth ? dayjs(dog?.dateOfBirth).format('YYYY') : '', dog?.gender, dog?.breed].map(
              (v, i, arr) =>
                v ? (
                  <span className="min-w-0 text-ellipsis truncate">
                    {v}
                    {i < arr.length - 1 ? <span className="text-gray-400"> | </span> : ''}
                  </span>
                ) : null,
            )}
          </div>

          {dog.diagnosticsReportUrl && (
            <Badge className="bg-edxred-500">
              <div className="flex items-center gap-2">
                <CheckCircle className="h-4 w-4" />
                <span className="font-semibold">Tested & Verified</span>
              </div>
            </Badge>
          )}
        </div>

        {dog?.avatar?.url ? (
          <img
            className="relative aspect-video bg-gray-100 rounded-md"
            src={makeEdxImageUrl(dog?.avatar?.url) || 'https://placehold.co/400x225?text=No+Photo'}
            alt=""
          />
        ) : (
          <div className="relative aspect-video bg-gray-100 rounded-md" />
        )}

        <hr className="my-4" />

        <div className="space-y-2 flex flex-col">
          <div className="flex flex-col justify-center items-center gap-3 flex-1">
            {onClickDiagnosticReport && (
              <DogButton
                classNames="flex justify-between text-white"
                onClickFn={onClickDiagnosticReport}
                variant="brand"
              >
                <span>Diagnostic Report</span> <MdViewHeadline />
              </DogButton>
            )}

            {onClickRecommendedProducts && (
              <DogButton classNames="flex justify-between" onClickFn={onClickRecommendedProducts}>
                <span>Recommended Products</span> <FaChevronRight />
              </DogButton>
            )}

            {onClickRecommendedServices && (
              <DogButton classNames="flex justify-between" onClickFn={onClickRecommendedServices}>
                <span>Recommended Services</span> <FaChevronRight />
              </DogButton>
            )}
          </div>
        </div>
      </CardContent>
    </div>
  )
}
