import React from 'react'
import { Card } from '@edx/react-common'

interface DogCardProps {
  children: React.ReactNode
}

export function DogCard({ children }: DogCardProps) {
  return (
    <Card className="bg-gradient-to-b from-doggradient-300/80 to-doggradient-100/80 shadow-md border-2 border-white backdrop-blur-[10px] rounded-3xl px-4 w-full flex flex-col relative">
      {children}
    </Card>
  )
}
