import { Fragment } from 'react/jsx-runtime'
import { DogIconNode } from './FamilyTree'

export const CircularGroup = () => {
  const circleRadius = [125, 250, 375] //[150, 300, 450]
  const dogCounts = [3, 6, 11]

  const renderCircle = (radius: number) => {
    return (
      <div
        className="absolute bg-transparent border-2 border-solid border-[#7F9198] rounded-full"
        style={{
          width: `${radius * 2}px`,
          height: `${radius * 2}px`,
          left: `${circleRadius[2] - radius}px`,
          top: `${circleRadius[2] - radius}px`,
        }}
      ></div>
    )
  }

  const renderMainDog = () => {
    const data = {
      name: 'REX',
      breed: 'German Shepherd',
      year: '2019',
      image:
        'https://s3-alpha-sig.figma.com/img/4515/38fa/ca5aee47975e31932b3f6341f08f9945?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Fcfasb4s3RQ32oHUPYPfOVhGx3~K8cxGQsV415v9CAen3it0M4sa2pqvp6PWkxP84mjG6-n6rRDj3p-PAAkWHkBZvwPwhyKWZMIPvmZ6e2LAe9Ky~-oJlmqARcvJcdpWVeHUNcbZp6OdPU-iucYoz-KcfGUKd5zWjI2L1~6jdiXk2uQD4Kj5epY3c0-PGu4TSFKXAl4GVjFQwTUJVHe3hjdihrrgjRKh-DhfEZjpVKSr6JDjfamJ540lPJEBh7AQ0Nj0FSOLtvcu1NoiopQnuB0QQ9t54IywTCr7JHhrDCEbxauEwTbAyH18u4KxbzgOY3dsUBWCLmMhnD6dYUgAgA__',
    }
    return (
      <div
        className="absolute"
        style={{
          width: `240px`,
          height: '240px',
          left: `${circleRadius[2] - 120}px`,
          top: `${circleRadius[2] - 120}px`,
        }}
      >
        <div className="flex justify-center items-center size-full">
          <div className="size-[140px] bg-[#8C0A0A] rounded-full p-[6px]">
            <img
              src={data.image}
              alt={data.name}
              className="w-full h-full rounded-full border-4 border-solid border-white box-border object-cover mb-[10px]"
            />
          </div>
        </div>
      </div>
    )
  }

  const renderSubDogs = () => {
    const positions = []
    for (let i = 0; i < dogCounts.length; i++) {
      for (let j = 0; j < dogCounts[i]; j++) {
        const angle = (((360 / dogCounts[i]) * j) / 180) * Math.PI
        const position = {
          x: circleRadius[2] + circleRadius[i] * Math.cos(angle),
          y: circleRadius[2] + circleRadius[i] * Math.sin(angle),
        }
        positions.push(position)
      }
    }
    return (
      <div className="relative w-full h-full">
        {positions.map((position, index) => (
          <Fragment key={index}>
            <div
              className="absolute"
              style={{ width: `140px`, height: '140px', left: `${position.x - 70}px`, top: `${position.y - 70}px` }}
            >
              <DogIconNode data={{ external: true }} />
            </div>
          </Fragment>
        ))}
      </div>
    )
  }

  return (
    <div className="w-full bg-white h-full overflow-auto">
      <div className="p-10 m-10 w-full h-full relative">
        {circleRadius.map((radius) => (
          <div key={radius}>{renderCircle(radius)}</div>
        ))}
        {renderMainDog()}
        {renderSubDogs()}
      </div>
    </div>
  )
}
