import React from 'react'
import { Link } from 'react-router-dom'
import { BiChevronLeft } from 'react-icons/bi'
import { DogProfileTabs, useDogProfileState } from '../state.ts'
import { cn } from '@edx/react-common'

interface DogProfileNavProps {}

export function DogProfileNav({}: DogProfileNavProps) {
  const state = useDogProfileState()

  return (
    <div className="flex justify-evenly w-full max-w-[1200px]">
      <Link to={'/'}>
        <div className="hover:bg-gray-800/10 cursor-pointer flex flex-col text-lg font-medium uppercase rounded p-1 px-6">
          <div className="flex items-center gap-2">
            <BiChevronLeft /> Dashboard
          </div>

          <div className="w-full h-1 rounded" />
        </div>
      </Link>
      {[
        DogProfileTabs.Behavior,
        DogProfileTabs.Physical,
        DogProfileTabs.Coat,
        DogProfileTabs.Health,
        DogProfileTabs.FindMyPack,
        DogProfileTabs.DogsLikeMe,
        DogProfileTabs.Resources,
      ].map((tab) => (
        <div
          className={cn(' cursor-pointer rounded p-1 px-4', {
            'bg-red-800/10': tab === state.activeTab,
          })}
          onClick={() => {
            state.actions.setActiveTab(tab as any)
          }}
        >
          <div className="flex flex-col space-y-1">
            <div className="text-lg font-medium uppercase">{tab}</div>

            {tab === state.activeTab && <div className="w-full h-1 rounded bg-edxred-700" />}
          </div>
        </div>
      ))}
    </div>
  )
}
