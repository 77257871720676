import React from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@edx/react-common'
import { useDiagnosticsState } from '../../../state-diagnostics.ts'
import { BehaviorSlider } from './BehaviorSlider/BehaviorSlider.tsx'
import { FaCircleInfo } from 'react-icons/fa6'
import { DogCard } from '../../../../../components/DogCard.tsx'
import { useDogProfileState } from '../../../state.ts'

interface DiagnosticsBehaviorCardProps {}

export function DiagnosticsBehaviorCard({}: DiagnosticsBehaviorCardProps) {
  const diagnostics = useDogProfileState((state) => state.diagnosticsDetail)
  const behaviorTraits = []
  for (const key in diagnostics.behaviorTraits) {
    behaviorTraits.push(diagnostics.behaviorTraits[key])
  }

  return (
    <DogCard>
      <CardHeader>
        <CardTitle className="text-edxred-500 font-montserrat text-lg uppercase">Behavior Traits</CardTitle>
      </CardHeader>

      <div className="p-6 pt-0">
        <hr />
      </div>

      <CardContent>
        <div className="grid grid-cols-2 gap-8 justify-items-center text-center">
          {behaviorTraits.map((trait) => {
            return (
              <div className="w-60 grid grid-cols-1 gap-2">
                <div className="inline relative">
                  <h4 className="inline font-bold text-lg">{trait.trait}</h4>
                  <FaCircleInfo className="inline absolute right-0 bottom-2" />
                </div>
                <div className="grid grid-cols-1 gap-1">
                  <BehaviorSlider result={trait.result} />
                  <div className="font-light">{trait.notes}</div>
                </div>
              </div>
            )
          })}
        </div>
      </CardContent>
    </DogCard>
  )
}
