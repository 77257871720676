import React from 'react'
import ReactFlow, { Handle, Position } from 'reactflow'
import 'reactflow/dist/style.css'
import CheckmarkIcon from '/svgs/check-mark.svg'
import ArrowRightupIcon from '/svgs/arrow-rightup.svg'
import USFlagIcon from '/svgs/us-flag.svg'

export const DogIconNode = ({ data }: any) => (
  <div className="size-[60px] bg-[#264653] rounded-full flex justify-center items-center">
    <div className="size-[90%] bg-white" style={{ mask: "url('/svgs/dog-icon.svg') no-repeat center / contain" }}></div>
    {!data?.external && (
      <>
        <Handle type="target" position={Position.Top} style={{ visibility: 'hidden' }} />
        <Handle type="source" position={Position.Bottom} style={{ visibility: 'hidden' }} />
      </>
    )}
  </div>
)

export const LabelNode = ({ data }: any) => (
  <div className="bg-white rounded-full border-4 border-solid border-[#264653] !py-1 !px-0 !w-[180px] text-center">
    <span className="text-xs text-black uppercase">{data.label}</span>
    <Handle type="target" position={Position.Top} style={{ visibility: 'hidden' }} />
    <Handle type="source" position={Position.Bottom} style={{ visibility: 'hidden' }} />
  </div>
)

export const MainDogNode = ({ data }: any) => (
  <div className="w-[240px] bg-white border border-solid border-[#264653] rounded-[8px] p-4 text-center text-black">
    <div className="flex justify-center items-start">
      <img src={ArrowRightupIcon} className="h-[22px]" />
      <div className="size-[140px] bg-[#8C0A0A] rounded-full p-[6px]">
        <img
          src={data.image}
          alt={data.name}
          className="w-full h-full rounded-full border-4 border-solid border-white box-border object-cover mb-[10px]"
        />
      </div>
      <img src={USFlagIcon} className="h-[22px]" />
    </div>
    <p className="text-[#9A2828] text-[30px] leading-[36px] mt-4 border-b border-solid border-[#D5D5D5]">{data.name}</p>
    <p className="text-lg font-bold leading-[22px] mt-3 text-[#5F4A37]">
      {data.year} | {data.breed}
    </p>
    <p className="mt-3 text-xl leading-[24px] text-[#5F4A37] flex items-center gap-2">
      <img src={CheckmarkIcon} />
      EDX Tested & Verified
    </p>
    {!data?.external && (
      <>
        <Handle type="target" position={Position.Top} style={{ visibility: 'hidden' }} />
        <Handle type="source" position={Position.Bottom} style={{ visibility: 'hidden' }} />
      </>
    )}
  </div>
)

const FamilyTree = () => {
  const R = 40,
    H = 60,
    OFF = 102
  const nodes: any = [
    // Great-grandparents
    { id: 'gg1', type: 'dogIcon', position: { x: 0, y: 0 } },
    { id: 'gg2', type: 'dogIcon', position: { x: R * 4, y: 0 } },
    { id: 'gg3', type: 'dogIcon', position: { x: R * 8, y: 0 } },
    { id: 'gg4', type: 'dogIcon', position: { x: R * 12, y: 0 } },
    { id: 'gg5', type: 'dogIcon', position: { x: R * 16, y: 0 } },
    { id: 'gg6', type: 'dogIcon', position: { x: R * 20, y: 0 } },
    { id: 'gg7', type: 'dogIcon', position: { x: R * 24, y: 0 } },
    { id: 'gg8', type: 'dogIcon', position: { x: R * 28, y: 0 } },
    { id: 'gg12-label', type: 'label', data: { label: 'GREAT-GRANDPARENTS' }, position: { x: R * 3 - OFF, y: H * 2 } },
    { id: 'gg34-label', type: 'label', data: { label: 'GREAT-GRANDPARENTS' }, position: { x: R * 11 - OFF, y: H * 2 } },
    { id: 'gg56-label', type: 'label', data: { label: 'GREAT-GRANDPARENTS' }, position: { x: R * 19 - OFF, y: H * 2 } },
    { id: 'gg78-label', type: 'label', data: { label: 'GREAT-GRANDPARENTS' }, position: { x: R * 27 - OFF, y: H * 2 } },

    // Grandparents
    { id: 'g1', type: 'dogIcon', position: { x: R * 8, y: H * 4 } },
    { id: 'g2', type: 'dogIcon', position: { x: R * 12, y: H * 4 } },
    { id: 'g3', type: 'dogIcon', position: { x: R * 16, y: H * 4 } },
    { id: 'g4', type: 'dogIcon', position: { x: R * 20, y: H * 4 } },
    { id: 'g12-label', type: 'label', data: { label: 'GRANDPARENTS' }, position: { x: R * 11 - OFF, y: H * 6 } },
    { id: 'g34-label', type: 'label', data: { label: 'GRANDPARENTS' }, position: { x: R * 19 - OFF, y: H * 6 } },

    // Parents
    { id: 'p1', type: 'dogIcon', position: { x: R * 12, y: H * 8 } },
    { id: 'p2', type: 'dogIcon', position: { x: R * 16, y: H * 8 } },
    { id: 'p12-label', type: 'label', data: { label: 'PARENTS' }, position: { x: R * 15 - OFF, y: H * 10 } },

    // Main dog
    {
      id: 'main',
      type: 'mainDog',
      data: {
        name: 'REX',
        breed: 'German Shepherd',
        year: '2019',
        image:
          'https://s3-alpha-sig.figma.com/img/4515/38fa/ca5aee47975e31932b3f6341f08f9945?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Fcfasb4s3RQ32oHUPYPfOVhGx3~K8cxGQsV415v9CAen3it0M4sa2pqvp6PWkxP84mjG6-n6rRDj3p-PAAkWHkBZvwPwhyKWZMIPvmZ6e2LAe9Ky~-oJlmqARcvJcdpWVeHUNcbZp6OdPU-iucYoz-KcfGUKd5zWjI2L1~6jdiXk2uQD4Kj5epY3c0-PGu4TSFKXAl4GVjFQwTUJVHe3hjdihrrgjRKh-DhfEZjpVKSr6JDjfamJ540lPJEBh7AQ0Nj0FSOLtvcu1NoiopQnuB0QQ9t54IywTCr7JHhrDCEbxauEwTbAyH18u4KxbzgOY3dsUBWCLmMhnD6dYUgAgA__',
      },
      position: { x: R * 15 - OFF - 30, y: H * 11.5 },
    },

    // Puppies
    { id: 'pup1', type: 'dogIcon', position: { x: R * 8, y: H * 19 } },
    { id: 'pup2', type: 'dogIcon', position: { x: R * 12, y: H * 19 } },
    { id: 'pup3', type: 'dogIcon', position: { x: R * 16, y: H * 19 } },
    { id: 'pup4', type: 'dogIcon', position: { x: R * 20, y: H * 19 } },
    { id: 'pup-label', type: 'label', data: { label: 'PUPPIES' }, position: { x: R * 15 - OFF, y: H * 17 } },
  ]

  const edges: any = [
    // Great-grandparents to Grandparents
    { id: 'e1-2', source: 'gg1', target: 'gg12-label', type: 'smoothstep' },
    { id: 'e2-2', source: 'gg2', target: 'gg12-label', type: 'smoothstep' },
    { id: 'e3-2', source: 'gg3', target: 'gg34-label', type: 'smoothstep' },
    { id: 'e4-2', source: 'gg4', target: 'gg34-label', type: 'smoothstep' },
    { id: 'e5-3', source: 'gg5', target: 'gg56-label', type: 'smoothstep' },
    { id: 'e6-3', source: 'gg6', target: 'gg56-label', type: 'smoothstep' },
    { id: 'e7-4', source: 'gg7', target: 'gg78-label', type: 'smoothstep' },
    { id: 'e8-4', source: 'gg8', target: 'gg78-label', type: 'smoothstep' },

    { id: 'l1-2', source: 'gg12-label', target: 'g1', type: 'smoothstep' },
    { id: 'l2-2', source: 'gg34-label', target: 'g2', type: 'smoothstep' },
    { id: 'l3-2', source: 'gg56-label', target: 'g3', type: 'smoothstep' },
    { id: 'l4-2', source: 'gg78-label', target: 'g4', type: 'smoothstep' },

    // Grandparents to Parents
    { id: 'e9-5', source: 'g1', target: 'g12-label', type: 'smoothstep' },
    { id: 'e10-5', source: 'g2', target: 'g12-label', type: 'smoothstep' },
    { id: 'e11-6', source: 'g3', target: 'g34-label', type: 'smoothstep' },
    { id: 'e12-6', source: 'g4', target: 'g34-label', type: 'smoothstep' },

    { id: 'l9-5', source: 'g12-label', target: 'p1', type: 'smoothstep' },
    { id: 'l10-5', source: 'g34-label', target: 'p2', type: 'smoothstep' },

    // Parents to Rex
    { id: 'e13-7', source: 'p1', target: 'p12-label', type: 'smoothstep' },
    { id: 'e14-7', source: 'p2', target: 'p12-label', type: 'smoothstep' },

    { id: 'l14-7', source: 'p12-label', target: 'main', type: 'smoothstep' },

    // Rex to Puppies
    { id: 'e15-8', source: 'main', target: 'pup-label', type: 'smoothstep' },

    { id: 'l15-8', source: 'pup-label', target: 'pup1', type: 'smoothstep' },
    { id: 'l16-8', source: 'pup-label', target: 'pup2', type: 'smoothstep' },
    { id: 'l17-8', source: 'pup-label', target: 'pup3', type: 'smoothstep' },
    { id: 'l18-8', source: 'pup-label', target: 'pup4', type: 'smoothstep' },
  ]

  return (
    <div className="w-full h-[80vh] overflow-hidden">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={{
          dogIcon: DogIconNode,
          label: LabelNode,
          mainDog: MainDogNode,
        }}
        fitView
        style={{ background: '#FFF' }}
      />
    </div>
  )
}

export default FamilyTree
