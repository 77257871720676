import React from 'react'
import logo from '/alphadog.png'
import { FaUser } from 'react-icons/fa6'
import { useToggle } from 'ahooks'
import { Link } from 'react-router-dom'
import { DogButton } from './DogButton'

interface NavBarProps {
  links: { onClick?: (link: { title: string; url: string }) => void; title: string; url: string }[]
  onClickActivate: () => void
  onClickLogo: () => void
  onClickProfile?: () => void
  onClickSignOut?: () => void
}

export function NavBar({ links, onClickActivate, onClickLogo, onClickProfile, onClickSignOut }: NavBarProps) {
  const [showMenu, { toggle: toggleMenu }] = useToggle<boolean>()

  return (
    <nav className="bg-edxwhite-100 drop-shadow-nav fixed w-full top-0">
      <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex px-2 lg:px-0 gap-x-[60px]">
            <div className="flex flex-shrink-0 items-center">
              <div className="max-w-[150px] cursor-pointer" onClick={onClickLogo}>
                <img src={logo} alt="Etalon" />
              </div>
            </div>
            <div className="hidden lg:flex items-center gap-x-[30px]">
              {links.map((link, index) =>
                link.onClick ? (
                  <div
                    className="cursor-pointer text-dogred-500 text-sm tracking-widest hover:underline"
                    onClick={() => link.onClick?.(link)}
                    key={index}
                  >
                    {link.title}
                  </div>
                ) : (
                  <Link
                    to={link.url}
                    key={index}
                    target="_blank"
                    className="text-dogred-500 text-sm tracking-widest hover:underline"
                  >
                    {link.title}
                  </Link>
                ),
              )}
            </div>
          </div>

          {/* Mobile */}
          <div className="flex items-center lg:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={toggleMenu}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>
              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Desktop */}
          <div className="hidden lg:flex lg:items-center" id="desktop-navbar-right">
            <div className="relative flex-shrink-0">
              <div className="flex gap-4">
                <DogButton
                  classNames="h-[34px] max-w-[170px] !text-sm tracking-wider bg-dogred-400 text-white border-0 px-5"
                  onClickFn={onClickActivate}
                >
                  Activate
                </DogButton>
                <DogButton
                  onClickFn={onClickSignOut}
                  classNames="h-[34px] max-w-[170px] !text-[15px] tracking-wider bg-dogred-200 text-dogred-700 border-0 px-5"
                >
                  Log out
                </DogButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}

      {showMenu && (
        <div className="lg:hidden" id="mobile-menu" onClick={toggleMenu}>
          <div className="flex flex-col">
            {links.map((link, index) => (
              <div className="border-t border-gray-200 py-4 w-full flex justify-center" key={index}>
                <Link
                  to={link.url}
                  key={index}
                  className="text-dogred-500 font-semibold text-sm tracking-widest hover:underline"
                >
                  {link.title}
                </Link>
              </div>
            ))}
          </div>

          <div className="border-y border-gray-200 pb-0">
            <div className="mt-0 space-y-0 text-center">
              <div
                className="block px-4 py-2 text-base font-medium bg-edxred-500 text-white hover:bg-edxred-700 hover:text-gray-100 cursor-pointer"
                onClick={onClickActivate}
              >
                Activate
              </div>
              <div
                className="border-t block px-4 py-2 text-base font-medium bg-dogred-100 text-dogred-500 hover:bg-gray-100 hover:text-gray-800 cursor-pointer"
                onClick={onClickSignOut}
              >
                Log out
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  )
}
