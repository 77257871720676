import React from 'react'
import { RecommendedServiceCategory } from '../../state.ts'
import { PawRating } from '../../../../components/PawRating.tsx'

interface ServiceCardProps {
  onClickService: () => void
  service: RecommendedServiceCategory['serviceList'][0]
}

export function ServiceCard({ onClickService, service }: ServiceCardProps) {
  return (
    <div
      className="cursor-pointer shadow-lg bg-gray-100 flex flex-col items-center p-2 rounded gap-2 max-w-[200px] min-w-[200px] border hover:border-edxred-500"
      onClick={onClickService}
    >
      <div>
        <img
          className="border border-gray-300 shadow rounded-full w-[75px] h-[75px] object-cover"
          src={service.imageUrl}
          alt="service"
        />
      </div>

      <div className="font-semibold text-center">{service.title}</div>

      <div className="text-sm font-light">{service.subtitle}</div>

      <PawRating value={service.rating} />

      <div>
        {service.rating.toFixed(0)} Paw Rating{' '}
        <span className="text-edxred-500 underline">{service.reviews.length} reviews</span>
      </div>

      <div />

      {/*<Button className="border border-edxred-700 rounded-2xl w-full" variant="outline" size="sm">*/}
      {/*  View Service*/}
      {/*</Button>*/}
    </div>
  )
}
