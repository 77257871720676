import React, { useState } from 'react'
import {
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@edx/react-common'
import { FaCheck, FaChevronDown, FaChevronUp } from 'react-icons/fa6'

interface SelectSearchableProps {
  options: string[]
  onValueChange: (value: string) => void
  placeholder?: string
  value?: string
}

export function SelectSearchable({ options, onValueChange, placeholder, value }: SelectSearchableProps) {
  const [open, setOpen] = useState(false)
  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="w-[200px] justify-between h-fit whitespace-pre-wrap">
          {value || placeholder || 'Select...'}
          {open ? (
            <FaChevronUp className="ml-2 h-3 w-3 shrink-0 opacity-50" />
          ) : (
            <FaChevronDown className="ml-2 h-3 w-3 shrink-0 opacity-50" />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search framework..." />
          <CommandList>
            <CommandEmpty>No framework found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option}
                  value={option}
                  onSelect={(currentValue) => {
                    onValueChange(currentValue === value ? '' : currentValue)
                    setOpen(false)
                  }}
                >
                  <FaCheck className={cn('mr-2 h-4 w-4', value === option ? 'opacity-100' : 'opacity-0')} />
                  {option}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
