import React from 'react'
import { RecommendedProductCategory } from '../../state.ts'
import { PawRating } from '../../../../components/PawRating.tsx'

interface ProductCardProps {
  onClickProduct: () => void
  product: RecommendedProductCategory['productList'][0]
}

export function ProductCard({ onClickProduct, product }: ProductCardProps) {
  return (
    <div
      className="cursor-pointer shadow-lg bg-gray-100 flex flex-col justify-between items-center p-2 rounded gap-2 max-w-[200px] min-w-[200px] border hover:border-edxred-500"
      onClick={onClickProduct}
    >
      <div>
        <img
          className="border border-gray-300 shadow rounded-full w-[75px] h-[75px] object-cover"
          src={product.imageUrl}
          alt="product"
        />
      </div>

      <div className="font-semibold text-center">{product.title}</div>

      <div className="text-sm font-light text-center">{product.subtitle}</div>

      <PawRating value={product.rating} />

      <div>
        {product.rating.toFixed(0)} Paw Rating{' '}
        <span className="text-edxred-500 underline">{product.reviews.length} reviews</span>
      </div>

      <div />

      {/*<Button className="border border-edxred-700 rounded-2xl w-full" variant="outline" size="sm">*/}
      {/*  View Product*/}
      {/*</Button>*/}
    </div>
  )
}
